import { TFunction } from "next-i18next";
import { TransferAddressUserItem } from "./wallet";
import {
  ClientRequestStatus,
  ClientRequestBoxDto,
  AuthorityName,
  TransferStatus,
  CustodyRequestStatus,
  ClientRequestType,
  ApprovalType,
  Blockchain,
  ClientRequestApprovalPolicy,
  WalletStatus,
  ClientSubmittedRequestDto,
  SigningLogType,
  ClientApprovalDto,
  TransferType,
  AggregatedDetailedWalletRequestDto,
  CustodyRequestV2Status,
  TransferV2Status,
} from "src/__generate__/api";
import { StatusType as ApproverSigningStatusType } from "src/components/Icon/ApproverSigningStatusIcon";
import { translate } from "src/locales";
import { IWalletStore } from "src/stores/WalletStore";
import { palette } from "src/themes/palette";
import { filterEmpty } from "src/utils/array";

export enum IntegrationRequestType {
  ALL = "ALL",
  TRANSFER = "TRANSFER",
  WALLET = "WALLET",
  ADDRESS_BOOK = "ADDRESS_BOOK",
}

export type IntegrationRequestTypeItem = {
  status: IntegrationRequestType;
  label: string;
};

export enum IntegrationRequestStatus {
  ALL = "ALL",
  REQUEST_PENDING = "REQUEST_PENDING",
  REQUEST_REJECT = "REQUEST_REJECT",
  REQUEST_APPROVAL = "REQUEST_APPROVAL",
  PROCEEDING_PENDING = "PROCEEDING_PENDING",
  PROCEEDING = "PROCEEDING",
  PROCEEDING_REJECT = "PROCEEDING_REJECT",
  PROCEEDING_CANCEL = "PROCEEDING_CANCEL",
  TRANSFER = "TRANSFER",
  TRANSFER_FAILED = "TRANSFER_FAILED",
  TRANSFER_COMPLETE = "TRANSFER_COMPLETE",
  DEPOSIT = "DEPOSIT",
  DEPOSIT_COMPLETE = "DEPOSIT_COMPLETE",
  CREATING = "CREATING",
  CREATING_FAILED = "CREATING_FAILED",
  CREATING_COMPLETE = "CREATING_COMPLETE",
}

export const isCustodyProceeding = (status: IntegrationRequestStatus) => {
  return [
    IntegrationRequestStatus.PROCEEDING_PENDING,
    IntegrationRequestStatus.PROCEEDING,
    IntegrationRequestStatus.PROCEEDING_REJECT,
    IntegrationRequestStatus.PROCEEDING_CANCEL,
    IntegrationRequestStatus.TRANSFER,
  ].some((custodyStatus) => custodyStatus === status);
};

export type IntegrationRequestStatusItem = {
  status: IntegrationRequestStatus;
  label: string;
  requestStatus: ClientRequestStatus[];
  custodyStatus: CustodyRequestStatus[];
  transferStatus: Array<TransferStatus | TransferV2Status>;
  walletStatus: WalletStatus[];
  transferType?: TransferType;
};

export type IntegrationRequestV2StatusItem = {
  status: IntegrationRequestStatus;
  label: string;
  requestStatus: ClientRequestStatus[];
  custodyStatus: CustodyRequestV2Status[];
  transferStatus: TransferV2Status[];
  walletStatus: WalletStatus[];
  transferType?: TransferType;
};

const byRequestType = (
  t?: TFunction,
): Record<IntegrationRequestType, IntegrationRequestTypeItem> => ({
  [IntegrationRequestType.ALL]: {
    status: IntegrationRequestType.ALL,
    label: translate(["allRequestType"], t),
  },
  [IntegrationRequestType.TRANSFER]: {
    status: IntegrationRequestType.TRANSFER,
    label: translate(["withdrawal"], t),
  },
  [IntegrationRequestType.WALLET]: {
    status: IntegrationRequestType.WALLET,
    label: translate(["walletCreation"], t),
  },
  [IntegrationRequestType.ADDRESS_BOOK]: {
    status: IntegrationRequestType.ADDRESS_BOOK,
    label: translate(["addressBook", "label"], t),
  },
});

export const getRequestTypes = (
  t?: TFunction,
): Array<IntegrationRequestTypeItem> => {
  return [
    byRequestType(t)[IntegrationRequestType.ALL],
    byRequestType(t)[IntegrationRequestType.TRANSFER],
    byRequestType(t)[IntegrationRequestType.WALLET],
    byRequestType(t)[IntegrationRequestType.ADDRESS_BOOK],
  ];
};

export const getRequestProceedingTypes = (
  t?: TFunction,
): Array<IntegrationRequestTypeItem> => {
  return [
    byRequestType(t)[IntegrationRequestType.ALL],
    byRequestType(t)[IntegrationRequestType.TRANSFER],
    byRequestType(t)[IntegrationRequestType.WALLET],
  ];
};

export const transformClientRequestTypeToIntegrationRequestType = (
  status: ClientRequestType,
) => {
  const map: Record<ClientRequestType, IntegrationRequestType> = {
    [ClientRequestType.Wallet]: IntegrationRequestType.WALLET,
    [ClientRequestType.Transfer]: IntegrationRequestType.TRANSFER,
    [ClientRequestType.AddressBook]: IntegrationRequestType.ADDRESS_BOOK,
  };
  return map[status];
};

export const transformIntegrationRequestTypeToClientRequestType = (
  status: IntegrationRequestType,
) => {
  const map: Partial<Record<IntegrationRequestType, ClientRequestType>> = {
    [IntegrationRequestType.WALLET]: ClientRequestType.Wallet,
    [IntegrationRequestType.TRANSFER]: ClientRequestType.Transfer,
    [IntegrationRequestType.ADDRESS_BOOK]: ClientRequestType.AddressBook,
  };
  return map[status];
};

export const transformRequestStatusToIntegrationRequestStatus = (
  status: ClientRequestStatus,
) => {
  const map: Record<ClientRequestStatus, IntegrationRequestStatus> = {
    [ClientRequestStatus.PendingApproval]:
      IntegrationRequestStatus.REQUEST_PENDING,
    [ClientRequestStatus.Rejected]: IntegrationRequestStatus.REQUEST_REJECT,
    [ClientRequestStatus.Approved]: IntegrationRequestStatus.REQUEST_APPROVAL,
    [ClientRequestStatus.Deleted]: IntegrationRequestStatus.ALL,
  };
  return map[status];
};

export const transformCustodyRequestStatusToIntegrationRequestStatus = (
  status: CustodyRequestStatus,
) => {
  const map: Record<CustodyRequestStatus, IntegrationRequestStatus> = {
    [CustodyRequestStatus.Requested]:
      IntegrationRequestStatus.PROCEEDING_PENDING,
    [CustodyRequestStatus.PendingSigning]:
      IntegrationRequestStatus.PROCEEDING_PENDING,
    [CustodyRequestStatus.PendingApproval]: IntegrationRequestStatus.PROCEEDING,
    [CustodyRequestStatus.PendingFinalApproval]:
      IntegrationRequestStatus.PROCEEDING,
    [CustodyRequestStatus.Declined]: IntegrationRequestStatus.PROCEEDING_REJECT,
    [CustodyRequestStatus.Canceled]: IntegrationRequestStatus.PROCEEDING_CANCEL,
    [CustodyRequestStatus.Processed]: IntegrationRequestStatus.TRANSFER,
  };
  return map[status];
};

export const transformCustodyRequestStatusToIntegrationRequestV2Status = (
  status: CustodyRequestV2Status,
) => {
  const map: Record<CustodyRequestV2Status, IntegrationRequestStatus> = {
    [CustodyRequestV2Status.Requested]:
      IntegrationRequestStatus.PROCEEDING_PENDING,
    [CustodyRequestV2Status.PendingSigning]:
      IntegrationRequestStatus.PROCEEDING_PENDING,
    [CustodyRequestV2Status.PendingApproval]:
      IntegrationRequestStatus.PROCEEDING,
    [CustodyRequestV2Status.PendingFinalApproval]:
      IntegrationRequestStatus.PROCEEDING,
    [CustodyRequestV2Status.Declined]:
      IntegrationRequestStatus.PROCEEDING_REJECT,
    [CustodyRequestV2Status.Canceled]:
      IntegrationRequestStatus.PROCEEDING_CANCEL,
    [CustodyRequestV2Status.Processed]: IntegrationRequestStatus.TRANSFER,
  };
  return map[status];
};

export const transformTransferTransactionStatusToIntegrationRequestStatus = (
  status: TransferStatus,
) => {
  const map: Record<TransferStatus, IntegrationRequestStatus> = {
    [TransferStatus.Processing]: IntegrationRequestStatus.PROCEEDING,
    [TransferStatus.Requested]: IntegrationRequestStatus.TRANSFER,
    [TransferStatus.Pending]: IntegrationRequestStatus.TRANSFER,
    [TransferStatus.Failed]: IntegrationRequestStatus.TRANSFER_FAILED,
    [TransferStatus.Mined]: IntegrationRequestStatus.TRANSFER,
    [TransferStatus.Reverted]: IntegrationRequestStatus.TRANSFER_FAILED,
    [TransferStatus.Confirmed]: IntegrationRequestStatus.TRANSFER_COMPLETE,
    [TransferStatus.Replaced]: IntegrationRequestStatus.TRANSFER_FAILED,
    [TransferStatus.InternalReverted]: IntegrationRequestStatus.TRANSFER_FAILED,
  };
  return map[status];
};

export const transformDepositToIntegrationRequestStatus = (
  status: TransferStatus,
) => {
  const map: Record<TransferStatus, IntegrationRequestStatus> = {
    [TransferStatus.Processing]: IntegrationRequestStatus.PROCEEDING,
    [TransferStatus.Requested]: IntegrationRequestStatus.TRANSFER,
    [TransferStatus.Pending]: IntegrationRequestStatus.TRANSFER,
    [TransferStatus.Failed]: IntegrationRequestStatus.TRANSFER_FAILED,
    [TransferStatus.Mined]: IntegrationRequestStatus.TRANSFER,
    [TransferStatus.Reverted]: IntegrationRequestStatus.TRANSFER_FAILED,
    [TransferStatus.Confirmed]: IntegrationRequestStatus.TRANSFER_COMPLETE,
    [TransferStatus.Replaced]: IntegrationRequestStatus.TRANSFER_FAILED,
    [TransferStatus.InternalReverted]: IntegrationRequestStatus.TRANSFER_FAILED,
  };
  return map[status];
};

export const transformTransferTransactionStatusToIntegrationRequestStatusV2 = (
  status: TransferV2Status,
) => {
  const map: Record<TransferV2Status, IntegrationRequestStatus> = {
    [TransferV2Status.Processing]: IntegrationRequestStatus.PROCEEDING,
    [TransferV2Status.Requested]: IntegrationRequestStatus.TRANSFER,
    [TransferV2Status.Pending]: IntegrationRequestStatus.TRANSFER,
    [TransferV2Status.SendFailed]: IntegrationRequestStatus.TRANSFER_FAILED,
    [TransferV2Status.TxFailed]: IntegrationRequestStatus.TRANSFER_FAILED,
    [TransferV2Status.Confirmed]: IntegrationRequestStatus.TRANSFER_COMPLETE,
  };
  return map[status];
};

export const transformDepositToIntegrationRequestStatusV2 = (
  status: TransferV2Status,
) => {
  const map: Record<TransferV2Status, IntegrationRequestStatus> = {
    [TransferV2Status.Processing]: IntegrationRequestStatus.PROCEEDING,
    [TransferV2Status.Requested]: IntegrationRequestStatus.DEPOSIT,
    [TransferV2Status.Pending]: IntegrationRequestStatus.DEPOSIT,
    [TransferV2Status.SendFailed]: IntegrationRequestStatus.TRANSFER_FAILED,
    [TransferV2Status.TxFailed]: IntegrationRequestStatus.TRANSFER_FAILED,
    [TransferV2Status.Confirmed]: IntegrationRequestStatus.DEPOSIT_COMPLETE,
  };
  return map[status];
};

export const transformWalletStatusToIntegrationRequestStatus = (
  status: WalletStatus,
) => {
  const map: Record<WalletStatus, IntegrationRequestStatus> = {
    [WalletStatus.Processing]: IntegrationRequestStatus.PROCEEDING,
    // [WalletStatus.Creating]: IntegrationRequestStatus.CREATING,
    [WalletStatus.Active]: IntegrationRequestStatus.CREATING_COMPLETE,
    // [WalletStatus.Inactive]: IntegrationRequestStatus.CREATING,
    [WalletStatus.Deleted]: IntegrationRequestStatus.CREATING_COMPLETE,
    // [WalletStatus.Failed]: IntegrationRequestStatus.CREATING_FAILED,
  };
  return map[status];
};

export const transformIntegrationRequestStatus = (params: {
  transferStatus: TransferStatus;
  clientRequestStatus?: ClientRequestStatus;
  custodyRequestStatus?: CustodyRequestStatus;
}): IntegrationRequestStatus => {
  const { transferStatus, clientRequestStatus, custodyRequestStatus } = params;
  if (!custodyRequestStatus && clientRequestStatus) {
    return transformRequestStatusToIntegrationRequestStatus(
      clientRequestStatus,
    );
  }
  if (
    CustodyRequestStatus.Processed === custodyRequestStatus &&
    transferStatus
  ) {
    return transformTransferTransactionStatusToIntegrationRequestStatus(
      transferStatus,
    );
  }
  return transformCustodyRequestStatusToIntegrationRequestStatus(
    custodyRequestStatus ?? CustodyRequestStatus.Requested,
  );
};

export const transformIntegrationRequestV2Status = (params: {
  transferStatus: TransferV2Status;
  clientRequestStatus?: ClientRequestStatus;
  custodyRequestStatus?: CustodyRequestV2Status;
}): IntegrationRequestStatus => {
  const { transferStatus, clientRequestStatus, custodyRequestStatus } = params;
  if (!custodyRequestStatus && clientRequestStatus) {
    return transformRequestStatusToIntegrationRequestStatus(
      clientRequestStatus,
    );
  }
  if (
    CustodyRequestV2Status.Processed === custodyRequestStatus &&
    transferStatus
  ) {
    return transformTransferTransactionStatusToIntegrationRequestStatusV2(
      transferStatus,
    );
  }
  return transformCustodyRequestStatusToIntegrationRequestV2Status(
    custodyRequestStatus ?? CustodyRequestV2Status.Requested,
  );
};

export const transformWalletIntegrationRequestStatus = (params: {
  walletStatus: WalletStatus;
  clientRequestStatus?: ClientRequestStatus;
  custodyRequestStatus?: CustodyRequestStatus;
}): IntegrationRequestStatus => {
  const { walletStatus, clientRequestStatus, custodyRequestStatus } = params;
  if (!custodyRequestStatus && clientRequestStatus) {
    return transformRequestStatusToIntegrationRequestStatus(
      clientRequestStatus,
    );
  }
  if (CustodyRequestStatus.Processed === custodyRequestStatus && walletStatus) {
    return transformWalletStatusToIntegrationRequestStatus(walletStatus);
  }
  return transformCustodyRequestStatusToIntegrationRequestStatus(
    custodyRequestStatus ?? CustodyRequestStatus.Requested,
  );
};

export const transformWalletIntegrationRequestV2Status = (params: {
  walletStatus: WalletStatus;
  clientRequestStatus?: ClientRequestStatus;
  custodyRequestStatus?: CustodyRequestV2Status;
}): IntegrationRequestStatus => {
  const { walletStatus, clientRequestStatus, custodyRequestStatus } = params;
  if (!custodyRequestStatus && clientRequestStatus) {
    return transformRequestStatusToIntegrationRequestStatus(
      clientRequestStatus,
    );
  }
  if (
    CustodyRequestV2Status.Processed === custodyRequestStatus &&
    walletStatus
  ) {
    return transformWalletStatusToIntegrationRequestStatus(walletStatus);
  }
  return transformCustodyRequestStatusToIntegrationRequestV2Status(
    custodyRequestStatus ?? CustodyRequestV2Status.Requested,
  );
};

export const getIntergationRequestStatusItemByStatus = (
  status: IntegrationRequestStatus,
  t?: TFunction,
) => {
  return byRequestStatus(t)[status];
};

const byRequestStatus = (
  t?: TFunction,
): Record<IntegrationRequestStatus, IntegrationRequestStatusItem> => ({
  [IntegrationRequestStatus.ALL]: {
    status: IntegrationRequestStatus.ALL,
    label: translate(["requestStatus", "ALL"], t),
    requestStatus: [],
    custodyStatus: [],
    transferStatus: [],
    walletStatus: [],
  },
  [IntegrationRequestStatus.REQUEST_PENDING]: {
    status: IntegrationRequestStatus.REQUEST_PENDING,
    label: translate(["requestStatus", "REQUEST_PENDING"], t),
    requestStatus: [ClientRequestStatus.PendingApproval],
    custodyStatus: [],
    transferStatus: [],
    walletStatus: [],
    transferType: TransferType.Withdrawal,
  },
  [IntegrationRequestStatus.REQUEST_REJECT]: {
    status: IntegrationRequestStatus.REQUEST_REJECT,
    label: translate(["requestStatus", "REQUEST_REJECT"], t),
    requestStatus: [ClientRequestStatus.Rejected],
    custodyStatus: [],
    transferStatus: [],
    walletStatus: [],
    transferType: TransferType.Withdrawal,
  },
  [IntegrationRequestStatus.REQUEST_APPROVAL]: {
    status: IntegrationRequestStatus.REQUEST_APPROVAL,
    label: translate(["requestStatus", "REQUEST_APPROVAL"], t),
    requestStatus: [ClientRequestStatus.Approved],
    custodyStatus: [],
    transferStatus: [],
    walletStatus: [],
    transferType: TransferType.Withdrawal,
  },
  [IntegrationRequestStatus.PROCEEDING_CANCEL]: {
    status: IntegrationRequestStatus.PROCEEDING_CANCEL,
    label: translate(["requestStatus", "PROCEEDING_CANCEL"], t),
    requestStatus: [],
    custodyStatus: [CustodyRequestStatus.Canceled],
    transferStatus: [],
    walletStatus: [],
    transferType: TransferType.Withdrawal,
  },
  [IntegrationRequestStatus.PROCEEDING_PENDING]: {
    status: IntegrationRequestStatus.PROCEEDING_PENDING,
    label: translate(["requestStatus", "PROCEEDING_PENDING"], t),
    requestStatus: [],
    custodyStatus: [
      CustodyRequestStatus.Requested,
      CustodyRequestStatus.PendingSigning,
    ],
    transferStatus: [],
    walletStatus: [],
    transferType: TransferType.Withdrawal,
  },
  [IntegrationRequestStatus.PROCEEDING]: {
    status: IntegrationRequestStatus.PROCEEDING,
    label: translate(["requestStatus", "PROCEEDING"], t),
    requestStatus: [],
    custodyStatus: [
      CustodyRequestStatus.PendingApproval,
      CustodyRequestStatus.PendingFinalApproval,
    ],
    transferStatus: [],
    walletStatus: [],
    transferType: TransferType.Withdrawal,
  },
  [IntegrationRequestStatus.PROCEEDING_REJECT]: {
    status: IntegrationRequestStatus.PROCEEDING_REJECT,
    label: translate(["requestStatus", "PROCEEDING_REJECT"], t),
    requestStatus: [],
    custodyStatus: [CustodyRequestStatus.Declined],
    transferStatus: [],
    walletStatus: [],
    transferType: TransferType.Withdrawal,
  },
  [IntegrationRequestStatus.TRANSFER]: {
    status: IntegrationRequestStatus.TRANSFER,
    label: translate(["requestStatus", "TRANSFER"], t),
    requestStatus: [],
    custodyStatus: [CustodyRequestStatus.Processed],
    transferStatus: [
      TransferStatus.Requested,
      TransferStatus.Pending,
      TransferStatus.Mined,
    ],
    walletStatus: [],
    transferType: TransferType.Withdrawal,
  },
  [IntegrationRequestStatus.TRANSFER_COMPLETE]: {
    status: IntegrationRequestStatus.TRANSFER_COMPLETE,
    label: translate(["requestStatus", "TRANSFER_COMPLETE"], t),
    requestStatus: [],
    custodyStatus: [],
    transferStatus: [TransferStatus.Confirmed],
    walletStatus: [],
    transferType: TransferType.Withdrawal,
  },
  [IntegrationRequestStatus.TRANSFER_FAILED]: {
    status: IntegrationRequestStatus.TRANSFER_FAILED,
    label: translate(["requestStatus", "TRANSFER_FAILED"], t),
    requestStatus: [],
    custodyStatus: [],
    transferStatus: [
      TransferStatus.Failed,
      TransferStatus.Reverted,
      TransferStatus.InternalReverted,
      TransferStatus.Replaced,
    ],
    walletStatus: [],
    transferType: TransferType.Withdrawal,
  },
  [IntegrationRequestStatus.DEPOSIT]: {
    status: IntegrationRequestStatus.DEPOSIT,
    label: translate(["requestStatus", "DEPOSIT"], t),
    requestStatus: [],
    custodyStatus: [],
    transferStatus: [TransferStatus.Mined],
    walletStatus: [],
    transferType: TransferType.Deposit,
  },
  [IntegrationRequestStatus.DEPOSIT_COMPLETE]: {
    status: IntegrationRequestStatus.DEPOSIT_COMPLETE,
    label: translate(["requestStatus", "DEPOSIT_COMPLETE"], t),
    requestStatus: [],
    custodyStatus: [],
    transferStatus: [TransferStatus.Confirmed],
    walletStatus: [],
    transferType: TransferType.Deposit,
  },
  [IntegrationRequestStatus.CREATING]: {
    status: IntegrationRequestStatus.CREATING,
    label: translate(["requestStatus", "CREATING"], t),
    requestStatus: [],
    custodyStatus: [],
    transferStatus: [],
    walletStatus: [],
  },
  [IntegrationRequestStatus.CREATING_FAILED]: {
    status: IntegrationRequestStatus.CREATING_FAILED,
    label: translate(["requestStatus", "CREATING_FAILED"], t),
    requestStatus: [],
    custodyStatus: [],
    transferStatus: [],
    walletStatus: [],
  },
  [IntegrationRequestStatus.CREATING_COMPLETE]: {
    status: IntegrationRequestStatus.CREATING_COMPLETE,
    label: translate(["requestStatus", "CREATING_COMPLETE"], t),
    requestStatus: [],
    custodyStatus: [],
    transferStatus: [],
    walletStatus: [],
  },
});

export const getIntergationRequestV2StatusItemByStatus = (
  status: IntegrationRequestStatus,
  t?: TFunction,
) => {
  return byRequestV2Status(t)[status];
};

const byRequestV2Status = (
  t?: TFunction,
): Record<IntegrationRequestStatus, IntegrationRequestV2StatusItem> => ({
  [IntegrationRequestStatus.ALL]: {
    status: IntegrationRequestStatus.ALL,
    label: translate(["requestStatus", "ALL"], t),
    requestStatus: [],
    custodyStatus: [],
    transferStatus: [],
    walletStatus: [],
  },
  [IntegrationRequestStatus.REQUEST_PENDING]: {
    status: IntegrationRequestStatus.REQUEST_PENDING,
    label: translate(["requestStatus", "REQUEST_PENDING"], t),
    requestStatus: [ClientRequestStatus.PendingApproval],
    custodyStatus: [],
    transferStatus: [],
    walletStatus: [],
    transferType: TransferType.Withdrawal,
  },
  [IntegrationRequestStatus.REQUEST_REJECT]: {
    status: IntegrationRequestStatus.REQUEST_REJECT,
    label: translate(["requestStatus", "REQUEST_REJECT"], t),
    requestStatus: [ClientRequestStatus.Rejected],
    custodyStatus: [],
    transferStatus: [],
    walletStatus: [],
    transferType: TransferType.Withdrawal,
  },
  [IntegrationRequestStatus.REQUEST_APPROVAL]: {
    status: IntegrationRequestStatus.REQUEST_APPROVAL,
    label: translate(["requestStatus", "REQUEST_APPROVAL"], t),
    requestStatus: [ClientRequestStatus.Approved],
    custodyStatus: [],
    transferStatus: [],
    walletStatus: [],
    transferType: TransferType.Withdrawal,
  },
  [IntegrationRequestStatus.PROCEEDING_CANCEL]: {
    status: IntegrationRequestStatus.PROCEEDING_CANCEL,
    label: translate(["requestStatus", "PROCEEDING_CANCEL"], t),
    requestStatus: [],
    custodyStatus: [CustodyRequestV2Status.Canceled],
    transferStatus: [],
    walletStatus: [],
    transferType: TransferType.Withdrawal,
  },
  [IntegrationRequestStatus.PROCEEDING_PENDING]: {
    status: IntegrationRequestStatus.PROCEEDING_PENDING,
    label: translate(["requestStatus", "PROCEEDING_PENDING"], t),
    requestStatus: [],
    custodyStatus: [
      CustodyRequestV2Status.Requested,
      CustodyRequestV2Status.PendingSigning,
    ],
    transferStatus: [],
    walletStatus: [],
    transferType: TransferType.Withdrawal,
  },
  [IntegrationRequestStatus.PROCEEDING]: {
    status: IntegrationRequestStatus.PROCEEDING,
    label: translate(["requestStatus", "PROCEEDING"], t),
    requestStatus: [],
    custodyStatus: [
      CustodyRequestV2Status.PendingApproval,
      CustodyRequestV2Status.PendingFinalApproval,
    ],
    transferStatus: [],
    walletStatus: [],
    transferType: TransferType.Withdrawal,
  },
  [IntegrationRequestStatus.PROCEEDING_REJECT]: {
    status: IntegrationRequestStatus.PROCEEDING_REJECT,
    label: translate(["requestStatus", "PROCEEDING_REJECT"], t),
    requestStatus: [],
    custodyStatus: [CustodyRequestV2Status.Declined],
    transferStatus: [],
    walletStatus: [],
    transferType: TransferType.Withdrawal,
  },
  [IntegrationRequestStatus.TRANSFER]: {
    status: IntegrationRequestStatus.TRANSFER,
    label: translate(["requestStatus", "TRANSFER"], t),
    requestStatus: [],
    custodyStatus: [CustodyRequestV2Status.Processed],
    transferStatus: [
      TransferV2Status.Requested,
      TransferV2Status.Pending,
      // TransferStatus.Mined,
    ],
    walletStatus: [],
    transferType: TransferType.Withdrawal,
  },
  [IntegrationRequestStatus.TRANSFER_COMPLETE]: {
    status: IntegrationRequestStatus.TRANSFER_COMPLETE,
    label: translate(["requestStatus", "TRANSFER_COMPLETE"], t),
    requestStatus: [],
    custodyStatus: [],
    transferStatus: [TransferV2Status.Confirmed],
    walletStatus: [],
    transferType: TransferType.Withdrawal,
  },
  [IntegrationRequestStatus.TRANSFER_FAILED]: {
    status: IntegrationRequestStatus.TRANSFER_FAILED,
    label: translate(["requestStatus", "TRANSFER_FAILED"], t),
    requestStatus: [],
    custodyStatus: [],
    transferStatus: [TransferV2Status.SendFailed, TransferV2Status.TxFailed],
    walletStatus: [],
    transferType: TransferType.Withdrawal,
  },
  [IntegrationRequestStatus.DEPOSIT]: {
    status: IntegrationRequestStatus.DEPOSIT,
    label: translate(["requestStatus", "DEPOSIT"], t),
    requestStatus: [],
    custodyStatus: [],
    transferStatus: [
      // TransferStatus.Mined
    ],
    walletStatus: [],
    transferType: TransferType.Deposit,
  },
  [IntegrationRequestStatus.DEPOSIT_COMPLETE]: {
    status: IntegrationRequestStatus.DEPOSIT_COMPLETE,
    label: translate(["requestStatus", "DEPOSIT_COMPLETE"], t),
    requestStatus: [],
    custodyStatus: [],
    transferStatus: [TransferV2Status.Confirmed],
    walletStatus: [],
    transferType: TransferType.Deposit,
  },
  [IntegrationRequestStatus.CREATING]: {
    status: IntegrationRequestStatus.CREATING,
    label: translate(["requestStatus", "CREATING"], t),
    requestStatus: [],
    custodyStatus: [],
    transferStatus: [],
    walletStatus: [],
  },
  [IntegrationRequestStatus.CREATING_FAILED]: {
    status: IntegrationRequestStatus.CREATING_FAILED,
    label: translate(["requestStatus", "CREATING_FAILED"], t),
    requestStatus: [],
    custodyStatus: [],
    transferStatus: [],
    walletStatus: [],
  },
  [IntegrationRequestStatus.CREATING_COMPLETE]: {
    status: IntegrationRequestStatus.CREATING_COMPLETE,
    label: translate(["requestStatus", "CREATING_COMPLETE"], t),
    requestStatus: [],
    custodyStatus: [],
    transferStatus: [],
    walletStatus: [],
  },
});

export const getIntergationProceedingRequestStatusItemByStatus = (
  status: IntegrationRequestStatus,
  t?: TFunction,
) => {
  return byProceedingRequestStatus(t)[status];
};

const byProceedingRequestStatus = (
  t?: TFunction,
): Partial<Record<IntegrationRequestStatus, IntegrationRequestStatusItem>> => ({
  [IntegrationRequestStatus.ALL]: {
    status: IntegrationRequestStatus.ALL,
    label: translate(["requestStatus", "ALL"], t),
    requestStatus: [],
    custodyStatus: [],
    transferStatus: [],
    walletStatus: [],
  },
  [IntegrationRequestStatus.PROCEEDING_CANCEL]: {
    status: IntegrationRequestStatus.PROCEEDING_CANCEL,
    label: translate(["requestStatus", "PROCEEDING_CANCEL"], t),
    requestStatus: [],
    custodyStatus: [CustodyRequestStatus.Canceled],
    transferStatus: [],
    walletStatus: [],
    transferType: TransferType.Withdrawal,
  },
  [IntegrationRequestStatus.PROCEEDING_PENDING]: {
    status: IntegrationRequestStatus.PROCEEDING_PENDING,
    label: translate(["requestStatus", "PROCEEDING_PENDING"], t),
    requestStatus: [],
    custodyStatus: [
      CustodyRequestStatus.Requested,
      CustodyRequestStatus.PendingSigning,
    ],
    transferStatus: [],
    walletStatus: [],
    transferType: TransferType.Withdrawal,
  },
  [IntegrationRequestStatus.PROCEEDING]: {
    status: IntegrationRequestStatus.PROCEEDING,
    label: translate(["requestStatus", "PROCEEDING"], t),
    requestStatus: [],
    custodyStatus: [
      CustodyRequestStatus.PendingApproval,
      CustodyRequestStatus.PendingFinalApproval,
    ],
    transferStatus: [],
    walletStatus: [],
    transferType: TransferType.Withdrawal,
  },
  [IntegrationRequestStatus.TRANSFER]: {
    status: IntegrationRequestStatus.TRANSFER,
    label: `${translate(["requestStatus", "TRANSFER"], t)} / ${translate(["requestStatus", "CREATING"], t)}`,
    requestStatus: [],
    custodyStatus: [CustodyRequestStatus.Processed],
    transferStatus: [
      TransferStatus.Requested,
      TransferStatus.Pending,
      TransferStatus.Mined,
      TransferStatus.Failed,
      TransferStatus.Reverted,
      TransferStatus.InternalReverted,
      TransferStatus.Replaced,
    ],
    walletStatus: [WalletStatus.Processing],
    transferType: TransferType.Withdrawal,
  },

  [IntegrationRequestStatus.TRANSFER_FAILED]: {
    status: IntegrationRequestStatus.TRANSFER_FAILED,
    label: translate(["requestStatus", "TRANSFER_FAILED"], t),
    requestStatus: [],
    custodyStatus: [],
    transferStatus: [
      TransferV2Status.SendFailed,
      TransferV2Status.TxFailed,
      TransferStatus.Failed,
    ],
    walletStatus: [],
    transferType: TransferType.Withdrawal,
  },
  [IntegrationRequestStatus.TRANSFER_COMPLETE]: {
    status: IntegrationRequestStatus.TRANSFER_COMPLETE,
    label: `${translate(["requestStatus", "TRANSFER_COMPLETE"], t)} / ${translate(["requestStatus", "CREATING_COMPLETE"], t)}`,
    requestStatus: [],
    custodyStatus: [],
    transferStatus: [TransferStatus.Confirmed],
    walletStatus: [WalletStatus.Active],
    transferType: TransferType.Withdrawal,
  },
});

export const getRequestStatusByStatus = (
  status: IntegrationRequestStatus,
  t?: TFunction,
) => {
  return byRequestStatus(t)[status];
};

export const getTransferRequestStatuses = (
  t?: TFunction,
): Array<IntegrationRequestStatusItem> => {
  return [
    byRequestStatus(t)[IntegrationRequestStatus.REQUEST_PENDING],
    byRequestStatus(t)[IntegrationRequestStatus.REQUEST_REJECT],
    byRequestStatus(t)[IntegrationRequestStatus.PROCEEDING_PENDING],
    byRequestStatus(t)[IntegrationRequestStatus.PROCEEDING_REJECT],
    byRequestStatus(t)[IntegrationRequestStatus.PROCEEDING_CANCEL],
    byRequestStatus(t)[IntegrationRequestStatus.PROCEEDING],
    byRequestStatus(t)[IntegrationRequestStatus.TRANSFER],
    byRequestStatus(t)[IntegrationRequestStatus.TRANSFER_COMPLETE],
    byRequestStatus(t)[IntegrationRequestStatus.TRANSFER_FAILED],
    byRequestStatus(t)[IntegrationRequestStatus.DEPOSIT],
    byRequestStatus(t)[IntegrationRequestStatus.DEPOSIT_COMPLETE],
  ];
};
export const getTransferRequestStatusesV2 = (
  t?: TFunction,
): Array<IntegrationRequestV2StatusItem> => {
  return [
    byRequestV2Status(t)[IntegrationRequestStatus.REQUEST_PENDING],
    byRequestV2Status(t)[IntegrationRequestStatus.REQUEST_REJECT],
    byRequestV2Status(t)[IntegrationRequestStatus.PROCEEDING_PENDING],
    byRequestV2Status(t)[IntegrationRequestStatus.PROCEEDING_REJECT],
    byRequestV2Status(t)[IntegrationRequestStatus.PROCEEDING_CANCEL],
    byRequestV2Status(t)[IntegrationRequestStatus.PROCEEDING],
    byRequestV2Status(t)[IntegrationRequestStatus.TRANSFER],
    byRequestV2Status(t)[IntegrationRequestStatus.TRANSFER_COMPLETE],
    byRequestV2Status(t)[IntegrationRequestStatus.TRANSFER_FAILED],
    byRequestV2Status(t)[IntegrationRequestStatus.DEPOSIT],
    byRequestV2Status(t)[IntegrationRequestStatus.DEPOSIT_COMPLETE],
  ];
};

export const getProceedingRequestStatuses = (
  t?: TFunction,
): Array<IntegrationRequestStatusItem> => {
  return filterEmpty([
    byProceedingRequestStatus(t)[IntegrationRequestStatus.ALL],
    byProceedingRequestStatus(t)[IntegrationRequestStatus.PROCEEDING_PENDING],
    byProceedingRequestStatus(t)[IntegrationRequestStatus.PROCEEDING_REJECT],
    byProceedingRequestStatus(t)[IntegrationRequestStatus.PROCEEDING_CANCEL],
    byProceedingRequestStatus(t)[IntegrationRequestStatus.PROCEEDING],
    byProceedingRequestStatus(t)[IntegrationRequestStatus.TRANSFER],
    byProceedingRequestStatus(t)[IntegrationRequestStatus.TRANSFER_FAILED],
    byProceedingRequestStatus(t)[IntegrationRequestStatus.TRANSFER_COMPLETE],
  ]);
};

export const getProceedingRequestWalletStatuses = (
  t?: TFunction,
): Array<IntegrationRequestStatusItem> => {
  return [
    byRequestStatus(t)[IntegrationRequestStatus.ALL],
    byRequestStatus(t)[IntegrationRequestStatus.PROCEEDING_PENDING],
    byRequestStatus(t)[IntegrationRequestStatus.PROCEEDING_CANCEL],
    byRequestStatus(t)[IntegrationRequestStatus.PROCEEDING],
    byRequestStatus(t)[IntegrationRequestStatus.CREATING],
    byRequestStatus(t)[IntegrationRequestStatus.CREATING_COMPLETE],
  ];
};

export const getSentRequestStatuses = (
  t?: TFunction,
): Array<IntegrationRequestStatusItem> => {
  return [
    byRequestStatus(t)[IntegrationRequestStatus.ALL],
    byRequestStatus(t)[IntegrationRequestStatus.REQUEST_PENDING],
    byRequestStatus(t)[IntegrationRequestStatus.REQUEST_APPROVAL],
    byRequestStatus(t)[IntegrationRequestStatus.REQUEST_REJECT],
  ];
};

export const getInboxRequestStatuses = (
  t?: TFunction,
): Array<IntegrationRequestStatusItem> => {
  return [
    byRequestStatus(t)[IntegrationRequestStatus.ALL],
    byRequestStatus(t)[IntegrationRequestStatus.REQUEST_PENDING],
    byRequestStatus(t)[IntegrationRequestStatus.REQUEST_APPROVAL],
    byRequestStatus(t)[IntegrationRequestStatus.REQUEST_REJECT],
  ];
};

export type RequestItem = ClientRequestBoxDto & {
  blockchain: Blockchain;
  integrationType: IntegrationRequestType;
  wallet: TransferAddressUserItem;
  to?: TransferAddressUserItem;
  integrationRequestStatus: IntegrationRequestStatus;
  detailedWalletRequestDto?: AggregatedDetailedWalletRequestDto;
};

const makeDetailedTransferRequestToRequestItem = (
  item: ClientRequestBoxDto,
  walletStore: IWalletStore,
): RequestItem => {
  const { type, detailedTransferRequest, status: clientRequestStatus } = item;

  if (!detailedTransferRequest) {
    throw new Error("not exist detailedTransferRequest");
  }
  const {
    walletId,
    to,
    blockchain,
    status: transferStatus,
  } = detailedTransferRequest.transfer;
  const custodyRequestStatus = item.custodyRequest?.status;

  const wallet = walletStore.masterWalletById(walletId);
  const toAddressBook = detailedTransferRequest.toAddressBook;

  return {
    ...item,
    blockchain,
    integrationType: transformClientRequestTypeToIntegrationRequestType(type),
    wallet: {
      userType: "master_wallet",
      address: wallet?.myAddress ?? "",
      masterWallet: {
        id: wallet?.id ?? "",
        name: wallet?.name ?? "",
      },
    },
    to: {
      userType: "address_book",
      address: toAddressBook?.address ?? to,
      addressBook: {
        id: toAddressBook?.id ?? "",
        name: toAddressBook?.name ?? to,
        addressBookMemo: toAddressBook?.addressBookMemo,
      },
    },
    integrationRequestStatus: transformIntegrationRequestStatus({
      transferStatus: transferStatus as TransferStatus,
      clientRequestStatus,
      custodyRequestStatus: custodyRequestStatus as CustodyRequestStatus,
    }),
  };
};

const makeDetailedAddressBookRequestToRequestItem = (
  item: ClientRequestBoxDto,
  walletStore: IWalletStore,
): RequestItem => {
  const { type, status, detailedAddressBookRequest } = item;
  const walletId = detailedAddressBookRequest?.addressBook?.walletId ?? "";
  const wallet = walletStore.masterWalletById(walletId);

  return {
    ...item,
    blockchain: wallet?.blockchain ?? Blockchain.Ethereum,
    integrationType: transformClientRequestTypeToIntegrationRequestType(type),
    wallet: {
      userType: "master_wallet",
      address: wallet?.myAddress ?? "",
      masterWallet: {
        id: wallet?.id ?? "",
        name: wallet?.name ?? "",
      },
    },
    integrationRequestStatus:
      transformRequestStatusToIntegrationRequestStatus(status),
  };
};

const makeDetailedWalletRequestDtoToRequestItem = (
  item: ClientSubmittedRequestDto,
): RequestItem => {
  const { type, status: clientRequestStatus, detailedWalletRequestDto } = item;

  if (!detailedWalletRequestDto) {
    throw new Error("not exist detailedWalletRequestDto");
  }
  const {
    id: walletId,
    name,
    status: walletStatus,
    blockchain,
    address,
  } = detailedWalletRequestDto.wallet;
  const custodyRequestStatus = item.custodyRequest?.status;

  return {
    ...item,
    blockchain,
    integrationType: transformClientRequestTypeToIntegrationRequestType(type),
    wallet: {
      userType: "master_wallet",
      address: address ?? "",
      masterWallet: {
        id: walletId,
        name,
      },
    },
    integrationRequestStatus: transformWalletIntegrationRequestStatus({
      walletStatus: walletStatus as WalletStatus,
      clientRequestStatus,
      custodyRequestStatus: custodyRequestStatus as CustodyRequestStatus,
    }),
  };
};

export const makeDetailedClientRequestDtoToRequestItem = (
  item: ClientRequestBoxDto & {
    detailedWalletRequestDto?: AggregatedDetailedWalletRequestDto;
  },
  walletStore: IWalletStore,
): RequestItem => {
  const { detailedTransferRequest, detailedAddressBookRequest } = item;

  if (detailedTransferRequest) {
    return makeDetailedTransferRequestToRequestItem(item, walletStore);
  }
  if (detailedAddressBookRequest) {
    return makeDetailedAddressBookRequestToRequestItem(item, walletStore);
  }
  return makeDetailedWalletRequestDtoToRequestItem(item);
};

export const makeMidSigningStatusItem = (params: {
  isSkipApprover: boolean;
  type?: ApprovalType;
}): {
  status: ApproverSigningStatusType;
  color: string;
} => {
  const { type, isSkipApprover } = params;
  if (type === ApprovalType.Allow) {
    return {
      status: "approve",
      color: palette.darkgrey.sub,
    };
  }
  if (type === ApprovalType.Deny) {
    return {
      status: "reject",
      color: palette.darkgrey.sub,
    };
  }
  if (isSkipApprover) {
    return {
      status: "skip_approve",
      color: palette.mediumgrey.dark,
    };
  }
  return {
    status: "pending",
    color: palette.darkgrey.sub,
  };
};

export const makeFinalSigningStatusItem = (params: {
  approvalPolicy: ClientRequestApprovalPolicy;
  midApproverType?: ApprovalType;
  type?: ApprovalType;
}): {
  status: ApproverSigningStatusType;
  color: string;
} => {
  const { approvalPolicy, type, midApproverType } = params;
  if (type === ApprovalType.Allow) {
    return {
      status: "approve",
      color: palette.darkgrey.sub,
    };
  }
  if (type === ApprovalType.Deny) {
    return {
      status: "reject",
      color: palette.darkgrey.sub,
    };
  }
  if (
    midApproverType === ApprovalType.Deny ||
    (Boolean(midApproverType) &&
      approvalPolicy === ClientRequestApprovalPolicy.WalletManagerApproval)
  ) {
    return {
      status: "skip_approve",
      color: palette.mediumgrey.dark,
    };
  }
  return {
    status: "pending",
    color: palette.darkgrey.sub,
  };
};

export const getClientRequestApprovalPolicyName = (
  approvalPolicy: ClientRequestApprovalPolicy,
  t?: TFunction,
) => {
  const map: Record<ClientRequestApprovalPolicy, string> = {
    [ClientRequestApprovalPolicy.OrganizationWalletsAdminApproval]: "",
    [ClientRequestApprovalPolicy.WalletOwnerApproval]: translate(
      ["walletRoles", "walletOwner"],
      t,
    ),
    [ClientRequestApprovalPolicy.WalletManagerApproval]: translate(
      ["walletRoles", "walletManager"],
      t,
    ),
  };
  return map[approvalPolicy];
};

export const getClientRequestApprovalPolicyName2 = (
  approvalPolicy: ClientRequestApprovalPolicy,
  t?: TFunction,
) => {
  const map: Record<ClientRequestApprovalPolicy, string> = {
    [ClientRequestApprovalPolicy.OrganizationWalletsAdminApproval]: "",
    [ClientRequestApprovalPolicy.WalletOwnerApproval]: translate(
      ["clientRequestApprovalPolicy", "walletOwner"],
      t,
    ),
    [ClientRequestApprovalPolicy.WalletManagerApproval]: translate(
      ["clientRequestApprovalPolicy", "walletManager"],
      t,
    ),
  };
  return map[approvalPolicy];
};

export const getDescriptionBySigningLogType = (type: SigningLogType) => {
  const map: Record<SigningLogType, string> = {
    [SigningLogType.QrCreation]: "트랜잭션 서명 QR 코드 발급",
    [SigningLogType.QrRecreation]: "트랜잭션 서명 QR 코드 재발급",
    [SigningLogType.ColdWalletSignature]:
      "콜드월렛에서 서명된 트랜잭션 QR 코드 발급",
    [SigningLogType.Confirmation]: "서명된 트랜잭션 QR 코드 등록",
  };
  return map[type];
};

export const getApprovalPolicyNameBy = (
  approvalPolicy: ClientRequestApprovalPolicy,
  t?: TFunction,
) => {
  const approvalPolicyName =
    approvalPolicy === ClientRequestApprovalPolicy.WalletOwnerApproval
      ? `${translate(["clientRequestApprovalPolicy", "walletOwner"], t)} ${translate(["clientRequestApprovalPolicy", "required"], t)}`
      : `${translate(["clientRequestApprovalPolicy", "walletManager"], t)} ${translate(["clientRequestApprovalPolicy", "required"], t)}`;
  return approvalPolicyName;
};

export const hasRequestApprovalPolicy = (params: {
  midApprover?: ClientApprovalDto;
  finalApprover?: ClientApprovalDto;
  authorityName?: AuthorityName;
}) => {
  const { midApprover, finalApprover, authorityName } = params;
  if (
    ![AuthorityName.WalletManager, AuthorityName.WalletOwner].some(
      (authority) => authority === authorityName,
    )
  ) {
    return false;
  }
  const isMidApproverCompleteStatus = [
    ApprovalType.Allow,
    ApprovalType.Deny,
  ].some((status) => status === midApprover?.type);
  const isFinalApproverCompleteStatus = [
    ApprovalType.Allow,
    ApprovalType.Deny,
  ].some((status) => status === finalApprover?.type);
  return !(
    (authorityName === AuthorityName.WalletManager &&
      isMidApproverCompleteStatus) ||
    (authorityName === AuthorityName.WalletOwner &&
      isFinalApproverCompleteStatus)
  );
};
