// tslint:disable:object-literal-sort-keys
const en = {
  test: "Test",
  developmentBannerAlert:
    "This dashboard runs in development environment and uses a separate account from the Testnet Mainnet dashboard.",
  testnetBannerAlert:
    "This dashboard runs on the Testnet and uses a separate account from the Mainnet dashboard.",
  expectedFeeAmount: "Expected fee",
  amount: "Amount",
  fromAddress: "Send from",
  receivedAddress: "Send to",
  withdrawMethod: "Withdrawal method",
  loginLabel: "Login data",
  time: "Time",
  utc: "(UTC+9)",
  requester: "Requester",
  wallet: "Wallet",
  withdrawWallet: "Withdrawal wallet",
  targetWallet: "Target wallet",
  addressName: "Address name",
  address: "Address",
  withdrawalAddress: "Withdrawal address",
  coin: "Coin",
  coinToken: "Coin/Token",
  coinTokenName: "Coin/Token Name",
  coinName: "Coin/Token Name",
  allCoinAndToken: "All coin/token",
  username: "Account",
  ipAddress: "IP address",
  ipAddress1: "IP address",
  registeredIpAddress: "Registered IP address",
  browser: "Browser",
  location: "Access location",
  confirmDashbord: "Check dashboard",
  masterWallet: "Master wallet",
  userWallet: "User wallet",
  withdrawal: "Withdrawal",
  deposit: "Deposit",
  next: "Next",
  password: "Password",
  confirm: "Confirm",
  name: "Name",
  ifHelp: "If you need assistance,",
  contact: "please contact",
  copyMessage: "is copied.",
  authButton: "Authenticate",
  transactionHash: "Transaction Hash",
  txHash: "TX hash",
  deposit1: "Deposit",
  status: "Status",
  warningWithdrawalLimit1:
    "Daily withdrawal limit is reset at 00:00 Korean time.",
  warningWIthdrawalLimit2:
    "Withdrawal by team member with Spender privilege is limited up to the withdrawal limit.",
  copyWalletAddressMessage: "'s wallet address is copied.",
  reissue: "Reissue",
  depositAddress: "Deposit address",
  activate: "Activate",
  withdrawalLimit: "Withdrawal limit",
  gasFeeWalletAddressCopy: "Copy fee wallet address",
  oldPassword: "Current password",
  newPasswordInput: "Enter new password",
  newPasswordConfirmInput: "Re-enter new password",
  verifyNewAndNewConfirmDiff:
    "The passwords entered do not match. Please check again.",
  create: "Create",
  cancel: "Cancel",
  approve: "Approve",
  reject: "Reject",
  depositAddressCopyMessage:
    "Deposit address has been copied to the clipboard.",
  previousStep: "To previous step",
  content: "Details",
  addIpAddress: "Add IP address",
  select: "Select",
  qrCode: "QR code",
  depositWarning: "Deposit precautions",
  withdraw: "Withdraw",
  failedCreateWallet: "Wallet creation failed",
  ifAbnormalAct: "If it is an abnormal act",
  delete: "Delete",
  edit: "Edit",
  contactEmail: "Email us for assistance.",
  close: "Close",
  createMasterWallet: "Create master wallet",
  forgottenOTP: "Lost OTP?",
  forgottenPassword: "Forgotten your password?",
  requestWithdraw: "Withdrawal request",
  withdrawAddressWhitelist: "Whitelisting withdrawal addresses",
  inactiveOnlyAdmin: "Only administrators can deactivate.",
  activeOnlyAdmin: "Only administrators can activate.",
  searchNameAndSymbol: "Search by name / symbol",
  emailAddress: "Email",
  email: "Email",
  save: "Save",
  newPassword: "New password",
  verifyWrongPassword: "Wrong password entered.",
  apiSync: "API sync",
  searchUserAndIDAndAddress: "Search by account / ID / address",
  verifyDuplicateLabel: "Duplicate names cannot be set.",
  security: "Security",
  walletName: "Wallet name",
  walletPassword: "Wallet password",
  setNewWalletPasswordMessage: "New wallet password has been set.",
  mismatchWalletPassword: "The account password does not match.",
  retryGasPriceCompleteMessage: "Resent as proper real-time gas fee.",
  setRecoverkitInfo: "Enter recovery kit info",
  setPassword: "Set password",
  setNewPassword: "Set a new password",
  setOTP: "Enter OTP",
  setName: "Set name",
  setWalletPassword: "Set wallet password",
  cannotRecoverWallet: "Unable to recover wallet",
  createMasterWallet1: "Create master wallet",
  total: "Total",
  count: "units",
  oneDaysWithdrawalLimit: "Daily withdrawal limit",
  oneTimeWithdrawalLimit: "One-time withdrawal limit",
  ID: "ID",
  creating: "Creating",
  createUserWallet: "Add user wallet",
  createDepositAddress: "Add deposit address",
  authority: "Authority",
  lessThanWithralLimit: "Withdrawal below the limit",
  delete1: "Delete",
  addAddress: "Add address",
  teamManager: "Manage Team",
  sixDigits: "6 digits",
  directInput: "Direct input",
  sendingCoin: "Coin to send",
  willWithdrawAmount: "Available Balance",
  withdrawAmount: "Amount to send",
  requestWithdrawMessage:
    "Withdrawable balance excluding the withdrawal requested amount",
  minimalExpectedAmount: "Minimum deposit",
  minimalExpectedAmountMessage:
    "Due to real-time changes in fees, \nyou must deposit more than the minimum deposit amount.",
  myGasFee: "Gas fee held",
  gasFeePayment: "Application of fee payment event",
  depositCoinAndToken: "Deposit coin/token to wallet",
  chargeGasFee: "Charge gas fee",
  inactiveWhitelist: "Whitelisting is deactivated.",
  ifAbnormalAct1: "If it is an abnormal attempt",
  reissueAccessToken: "reissue the user’s access token from the dashboard.",
  withdrawInfo: "Withdrawal info",
  ifAbnormalAct2: "If it is an abnormal withdrawal attempt",
  completeActiveWhitelist: "Whitelisting activation completed",
  isActiveWhitelisting: "Do you want to activate whitelisting?",
  completeInactiveWhitelist: "Whitelisting deactivating completed",
  isInactiveWhitelisting: "Do you want to deactivate whitelisting?",
  inactive: "Deactivate",
  emptyCoin: "No coin.",
  empty: "Empty",
  availableCurrentRegisteredIp:
    "From now on, API calls can only be made with registered IP address.",
  change: "Change",
  retryGasFeeMessage:
    "Ethereum proper real-time gas fee sharply rose, delaying the mining. If you want to complete mining within 5 minutes, please resend proper real-time gas fee.",
  retryRealGasFee: "Resend as proper real-time gas fee",
  gasPrice: "Gas price",
  fee: "Fee",
  retryRealGasFeeMessage: "Resend the proper real-time gas fee",
  newTransactionMessage: "New transaction occurs.",
  realGasFee: "Proper real-time gas fee",
  realNetworkBasedGasFee:
    "Gas fee for transaction mining within 5 minutes based on real-time network",
  doNotBeSurprised: "Don't be surprised!",
  basedTransactionMessage:
    "Existing transaction with lower gas fee may be mined first.",
  sameTransactionMessage:
    "Multiple identical transactions occur, but when one of them is mined, the remaining\ntransactions are cancelled.",
  dealDuplicateConfirmMessage:
    "Duplicate confirmation of transactions never occurs.",
  retryCancel: "Cancel resend",
  retry: "Resend",
  searchTransactionHash: "You can search by transaction hash.",
  setPassword1: "Please enter the password of.",
  masterWalletUnderUserWalletMessage:
    "Blanket application to all user wallets below the Master Wallet.",
  applyEachUserWalletMessage:
    "Respective limit is applied to each user wallet.",
  withdrawalLimitMessage:
    "Team member with withdrawal privilege can withdraw only within the withdrawal limit.",
  warningConfirm1: " confirmations, it will be reflected in the wallet.",
  warningConfirm2: "Be sure to confirm the deposit address before sending.",
  warningConfirm3:
    "Gas fee must be deposited in the gas fee wallet, not this wallet.",
  warningConfirm4: " can be deposited.",
  request: "Request",
  approvalPolicy: "Approval Line",
  approvalStatus: "Approval Status",
  custodyStatus: "Processing Status",
  member: "Member",
  invite: "Invite",
  caution: "Caution",
  invited: "Invited",
  approvalMemoPlaceholder:
    "Please provide the reason for approval. (Limit: 280 characters)",
  memo: "Memo",
  walletCreation: "Wallet Creation",
  registration: "Registration",
  allRequestType: "All",
  skip: "Skip",
  walletAddress: "Wallet Address",
  registerCoin: "Register Coin",
  blockchain: "Blockchain",
  registeredAt: "Registered At",
  registerer: "Registerer",
  register: "Register",
  symbol: "Symbol",
  allWallets: "All Wallets",
  balance: "Balance",
  max: "Max",
  addButton: "Add",
  contactSupport: "Contact Support",
  language: "Language",
  dontShowAgain: "Do not show again",
  libs: {
    message: {
      VERIFIED_IP: "The IP address is verified. Please login.",
    },
    error: {
      UNKOWN_ERROR: "An unknown error has occurred. Please try again",
      WRONG_WALLET_NAME: "Wrong wallet name.",
      EXISTS_WALLET_NAME:
        "The name cannot be the same as other master wallets.",
      WRONG_ADDRESS: "Wrong address entered.",
      LACK_BALANCE_AMOUNT: "Insufficient balance.",
      NETWORK_ERROR: "An unknown error has occurred. Please try again",
      WRONG_RECOVERKIT_INPUT: "Data does not match.",
      ACCESS_GUEST: "Access not permitted.",
      FORBIDDEN_ERROR_HOUR:
        "You were automatically logged out for security as 1 hour passed after logging in.\nPlease log in again.",
      GASFEE_INSUFFICIENT_FUNDS_CREATE_WALLET:
        "Wallet creation failed due to insufficient gas fee.",
      GASFEE_INSUFFICIENT_WITHDRAW_WALLET:
        "Insufficient balance in the gas fee wallet.",
      EMPTY_ALLOWED_ADDRESS_NAME: "Enter name.",
      EXISTS_ALLOWED_ADDRESS: "This address is already registered.",
      ALREADY_EXISTS_ALLOWED_ADDRESS_NAME:
        "The name cannot be the same as other addresses.",
      SAME_GAS_PRICE:
        "It has already been retransmitted at the same gas price.",
      UTXO_LOCKED:
        "The withdrawal failed because a previous transaction has not been completed. Please complete the previous transaction or try again after 40 seconds.",
      UNAUTHORIZED:
        "You were automatically logged out for security as 1 hour passed after logging in.\nPlease log in again.",
      EMAIL_DOES_NOT_EXISTS: "This email does not exist.",
      INVALID_PASSWORD: "An incorrect password entered.",
      OTP_AUTHENTICATION_FAILED: "An incorrect authentication code entered.",
      NOT_VERIFIED_IP:
        "Login attempted from a new IP address. Please check the email sent to \n%email% and verify the email address.",
      TIMEOUT_IP:
        "The validity of the authentication code has expired after 24 hours.\nPlease log in again to receive a new authentication email.",
      ALREADY_VERIFIED_IP: "Already verified.",
      INVALID_IP_VERIFY_REQUEST:
        "IP address differs from the address attempted to log in.\nPlease authenticate in the same place and network environment.\nFor recurring issues, please contact the customer service.",
      FAIL_TO_REPLACE_TRANSACTION:
        "No resending because real-time proper gas fee is lower than the gas fee of the transaction.",
      ALREADY_PROCESSED_TRANSACTION: "Transaction already mined.",
      ALREADY_REPLACED_TRANSACTION: "Transaction already replaced.",
      INVALID_MINIMUM_BALANCE:
        "Failed to create wallet due to insufficient gas fee.",
      ALREADY_USED_PASSPHRASE:
        "You used this password recently. Please use a different password.",
      ALREADY_PROCESSING_CSV: "Generating CSV. Please click again later.",
      TRY_TO_PROCESS_CSV:
        "Generate CSV. Please click the button again after 5 minutes.",
      ALREADY_AUTHENTICATED_OTP: "The OTP code has already been used.",
      SAME_EMAIL_INVALID_PASSWORD:
        "The password is the same as the account's email address. Please set a different password.",
      PASSPHRASE_MAX_ATTEMPT:
        "You have entered the password incorrectly 5 times. Login is now blocked. \nClick the [Forgotten your password?] button to reset your password.",
      SAME_PREVIOUS_PASSWORD_INVALID_PASSWORD:
        "The new password is the same as the current password. \nPlease set a different password.",
      EXPIRED_PASSWORD: "The password has expired.",
      EXPIRED_TOKEN: "The link has expired.",
      EXPIRED_ACTION:
        "You were automatically logged out due to inactivity for a certain period.",
      NOT_COMPLETED_CUSTODY_REQUEST_EXISTS:
        "All pending requests must be completed before processing a new request.",
      NOT_IDENTICAL_SIGNER: "Another manager is already signing.",
      ALREADY_CREATED_ADDRESS_BOOK:
        "This address name/address has already been requested for registration approval.",
      EXPIRED_INVITATION:
        "The invitation email has expired after 24 hours. \nPlease ask the administrator to resend the invitation email.",
      INVALID_INVITATION:
        "Please check for the newly received invitation email.",
      CANCELED_INVITATION:
        "The administrator has canceled the invitation. \nPlease ask the administrator to resend the invitation email.",
      ALREADY_SIGNED_UP_ACCOUNT: "This email address is already registered.",
      DUPLICATED_ADDRESS_BOOK_DELETE_REQUEST:
        "The address book has already been requested for deletion approval.",
      DUPLICATED_ADDRESS_BOOK_ADDRESS:
        "The address book has already been requested for registration approval.",
      DUPLICATED_ADDRESS_BOOK_NAME:
        "This address book has already been deleted.",
      DUPLICATED_EMAIL: "This email address is already registered as a member.",
      ALREADY_INVITED_ACCOUNT_BY_DIFFERENT_ORGANIZATION:
        "This account belongs to a different organization.",
      SESSION_TIME_OUT:
        "You have been logged out because you logged in from another browser.",
      INVALID_TRANSACTION: "This withdrawal request cannot be processed.",
      ALREADY_EXISTING_COIN: "This coin has already been registered.",
      INVALID_QRCODE: "The QR code is invalid.",
      INVALID_ORDER_QRCODE:
        "The order of the QR codes is incorrect. \nPlease scan them in the order indicated.",
      INVALID_COMPONENT_QRCODE:
        "Please scan the QR code issued by the cold wallet.",
    },
  },
  utils: {
    blockchain: {
      getBlockchainItemBy: {
        bitcoin: "Bitcoin",
        bitcoin_deprecated: "Bitcoin_deprecated",
        ethereum: "Ethereum",
        ethereum_deprecated: "Ethereum_deprecated",
        kaia: "Kaia",
        kaia_deprecated: "Kaia_deprecated",
        baobab: "Kaia",
        polygon: "Polygon",
        polygon_deprecated: "Polygon_deprecated",
        amoy: "Polygon",
        overProtocol: "OverProtocol",
        bsc: "BinanceSmartChain",
        wemix: "Wemix",
        xpla: "XPLA",
        bizauto: "BizAuto",
        arbitrumOne: "ArbitrumOne",
      },
      makeDepositWarnings: {
        ethWarning: {
          line1Prefix: "After ",
          line1Postfix: "$t(warningConfirm1)",
          line2: "$t(warningConfirm2)",
          line3: "$t(warningConfirm3)",
        },
        kaiaWarning: {
          line1: "$t(warningConfirm2)",
          line2: "$t(warningConfirm3)",
        },
        polygonWarning: {
          line1: "$t(warningConfirm2)",
          line2: "$t(warningConfirm3)",
        },
        overWarning: {
          line1: "$t(warningConfirm2)",
          line2: "$t(warningConfirm3)",
        },
        bscWarning: {
          line1: "$t(warningConfirm2)",
          line2: "$t(warningConfirm3)",
        },
        xplaWarning: {
          line1: "$t(warningConfirm2)",
          line2: "$t(warningConfirm3)",
        },
        wemixWarning: {
          line1: "$t(warningConfirm2)",
          line2: "$t(warningConfirm3)",
        },
        bizautoWarning: {
          line1: "$t(warningConfirm2)",
          line2: "$t(warningConfirm3)",
        },
        arbitrumOneWarning: {
          line1: "$t(warningConfirm2)",
          line2: "$t(warningConfirm3)",
        },
        bitcoinWarning: {
          line1Prefix: "After ",
          line1Postfix: "$t(warningConfirm1)",
          line2: "$t(warningConfirm2)",
        },
        common: {
          token: " Token",
          line1Prefix: "Only ",
          line1Postfix: "$t(warningConfirm4)",
        },
      },
      makeGasFeeDepositFormWarnings: {
        ethWarning: {
          line1Prefix: "After ",
          line1Postfix: "$t(warningConfirm1)",
        },
        kaiaWarning: {
          line1Prefix: "After ",
          line1Postfix: "$t(warningConfirm1)",
        },
        polygonWarning: {
          line1Prefix: "After ",
          line1Postfix: "$t(warningConfirm1)",
        },
        overWarning: {
          line1Prefix: "After ",
          line1Postfix: "$t(warningConfirm1)",
        },
        bscWarning: {
          line1Prefix: "After ",
          line1Postfix: "$t(warningConfirm1)",
        },
        xplaWarning: {
          line1Prefix: "After ",
          line1Postfix: "$t(warningConfirm1)",
        },
        wemixWarning: {
          line1Prefix: "After ",
          line1Postfix: "$t(warningConfirm1)",
        },
        bizautoWarning: {
          line1Prefix: "After ",
          line1Postfix: "$t(warningConfirm1)",
        },
        arbitrumOneWarning: {
          line1Prefix: "After ",
          line1Postfix: "$t(warningConfirm1)",
        },
        common: {
          line1Prefix: "Only ",
          line1Postfix: "$t(warningConfirm4)",
          line2:
            "The address is a separate wallet address that is different from the master wallet address.",
        },
      },
      makeGasFeeDepositWarnings: {
        ethWarning: {
          line1Prefix: "Only ",
          line1Postfix: "$t(warningConfirm4)",
          line2Prefix: "After ",
          line2Postfix: "$t(warningConfirm1)",
          line3: "$t(warningConfirm2)",
        },
        kaiaWarning: {
          line1Prefix: "Only ",
          line1Postfix: "$t(warningConfirm4)",
          line2: "$t(warningConfirm2)",
        },
        polygonWarning: {
          line1Prefix: "Only ",
          line1Postfix: "$t(warningConfirm4)",
          line2: "$t(warningConfirm2)",
        },
        overWarning: {
          line1Prefix: "Only ",
          line1Postfix: "$t(warningConfirm4)",
          line2: "$t(warningConfirm2)",
        },
        bscWarning: {
          line1Prefix: "Only ",
          line1Postfix: "$t(warningConfirm4)",
          line2: "$t(warningConfirm2)",
        },
        xplaWarning: {
          line1Prefix: "Only ",
          line1Postfix: "$t(warningConfirm4)",
          line2: "$t(warningConfirm2)",
        },
        bizautoWarning: {
          line1Prefix: "Only ",
          line1Postfix: "$t(warningConfirm4)",
          line2: "$t(warningConfirm2)",
        },
        wemixWarning: {
          line1Prefix: "Only ",
          line1Postfix: "$t(warningConfirm4)",
          line2: "$t(warningConfirm2)",
        },
        arbitrumOneWarning: {
          line1Prefix: "Only ",
          line1Postfix: "$t(warningConfirm4)",
          line2: "$t(warningConfirm2)",
        },
      },
      walletNameByBlockchain: {
        masterWallet: "$t(masterWallet)",
        userWallet: "$t(userWallet)",
        wallet: "$t(wallet)",
      },
    },
    number: {
      getOverFloatingAndNotNumber: {
        regexMessage1: "Decimal ",
        regexMessage2: " digit is the maximum you can enter.",
        regexMessage3: "Enter numbers.",
      },
    },
  },
  stores: {
    model: {
      user: {
        transformRoleName: {
          admin: "Admin",
          spender: "Spender",
          viewer: "Viewer",
          haechi: "Haechi",
        },
      },
    },
    balances: {
      selectedAllBalanceName: "$t(allCoinAndToken)",
    },
    gasFeeUsages: {
      contentByTransactionType: {
        withdrawal: "$t(withdrawal)",
        deposit: "$t(deposit)",
        unknownExternalCall: "Unknown external call",
        smartContractCall: "Smart contract call",
        masterWalletDeployment: "$t(createMasterWallet)",
        userWalletDeployment: "Create user wallet",
      },
    },
    recoverWallet: {
      verifyPassphaseDiff: "Data does not match.",
    },
    withdrawPolicies: {
      policyDescription: {
        and: " and ",
        count: " others",
      },
    },
    organization: {
      error: "Organization is not selected.",
    },
  },
  pages: {
    "401": {
      title: "Page access denied.",
      description: "Only administrator can confirm the page.",
    },
    "404": {
      title: "Page not found.",
      description:
        "If the error persists, please contact us at the email below.",
    },
    account: {
      otp: {
        guide: {
          title: "Set OTP",
          description:
            "OTP setting is required for the first login. \nPlease scant the QR Code on Google Authenticator.",
          androidDownloadButton: "Download for Android phone",
          iosDownloadButton: "Download for iPhone",
          nextButton: "$t(next)",
        },
        register: {
          submitButton: "$t(authButton)",
          forgottenOtp: "$t(forgottenOTP)",
        },
      },
      login: {
        title: "Login",
        emailLabel: "Email",
        passwordLabel: "$t(password)",
        forgottenPassword: "$t(forgottenPassword)",
        loginButton: "Login",
      },
    },
    dashboard: {
      balanceReport: {
        title: {
          description1: "Hello, ",
          description2: "",
        },
        empty: {
          title: "No Wallets Available",
          description: "There are no wallets created or associated with you.",
        },
        createWallet: "Create Wallet",
      },
      operation: {
        authority: {
          appbarLeftCaption: "$t(teamManager)",
          title: "Collaborate more easily and securely.",
          description:
            "Administrator can set team members.\nGrant roles and authorities for each team member.",
          authorityInfoButton: "What is the authorization each role has?",
        },
      },
      request: {
        withdraw: {
          appbarLeftCaption: "$t(requestWithdraw)",
          title: "Confirm withdrawal request.",
          description:
            "Withdrawals over the limit require administrator approval.\nConfirm the request and approve the withdrawal.",
          withdrawApprovalCompleteTitle: "Withdrawal approved",
          withdrawApprovalCompleteSubmitButton: "$t(confirm)",
          copyAddressCompleteMessage: "$t(copyMessage)",
          copyWalletNameCompleteMessage: "$t(copyWalletAddressMessage)",
        },
        inbox: {
          title: "Received Requests",
        },
        outbox: {
          title: "Sent Requests",
        },
        expiredPassword: {
          title: "Set New Password",
          step1: "Enter New Password",
          step2: "OTP Verification",
          step3: "Change Complete",
        },
      },
      wallet: {
        "[blockchain]": {
          "[id]": {
            user: {
              "[userWalletId]": {
                userAddressCopyMessage: "Copy user wallet address",
                copyAddressCompleteMessage: "$t(copyMessage)",
              },
            },
            allowedAddress: {
              title:
                "Securely withdraw money only to addresses allowed in advance.",
              description:
                "Please enable whitelisting to block withdrawals to unauthorized addresses.\nPlease register addresses to allow withdrawal.",
              contentTitle: "$t(withdrawAddressWhitelist)",
              whitelistInactivated: "$t(inactiveOnlyAdmin)",
              whitelistActivated: "$t(activeOnlyAdmin)",
              canNotLoadMasterWallet: "Could not load master wallet.",
            },
            index: {
              walletAddressCopyMessage: "Copy wallet address",
              copyAddressCompleteMessage: "$t(copyMessage)",
            },
            policy: {
              title: "$t(withdrawalLimit)",
              description: "You can set a withdrawal limit by coin/token.",
              policyInfoButton: "How does the limit apply?",
              searchPlaceholder: "$t(searchNameAndSymbol)",
            },
          },
          gasFee: {
            gasFeeName: "Fee wallet",
            gasFeePayment: "Apply fee event",
            gasFeeAddressCopyMessage: "$t(gasFeeWalletAddressCopy)",
            copyAddressCompleteMessage: "$t(copyMessage)",
          },
        },
      },
      account: {
        appbarLeftCaption: "Account info",
        usernameLabel: "User name",
        firstName: "First name",
        lastName: "$t(name)",
        emailLabel: "$t(emailAddress)",
        saveButton: "$t(save)",
        passwordLabel: "$t(password)",
        oldPasswordLabel: "$t(oldPassword)",
        oldPasswordPlaceholder: "$t(oldPassword)",
        newPasswordLabel: "$t(newPassword)",
        newPasswordPlaceholder: "$t(newPasswordInput)",
        newPasswordConfirmPlaceholder: "$t(newPasswordConfirmInput)",
        forgottenPassword: "Forgotten your password?",
        otpButton: "$t(authButton)",
        closeButton: "$t(close)",
        verifyUsernameRule: "You can enter only English or Korean.",
        verifyNewAndNewConfirmDiff: "$t(verifyNewAndNewConfirmDiff)",
        verifyWrongPassword: "$t(verifyWrongPassword)",
      },
    },
  },
  organizations: {
    dashboard: {
      account: {
        tab: {
          account: {},
          locale: {
            languageLabel: "Language",
            timezoneLabel: "Time Zone",
            saveButton: "$t(save)",
          },
        },
      },
      tab: {
        gasFee: {
          deposit: {
            depositAddressCopyMessage: "$t(depositAddressCopyMessage)",
          },
          usage: {
            searchPlaceholder: "Search transaction hash",
          },
        },
        balance: {
          searchPlaceholder: "$t(searchNameAndSymbol)",
        },
        deposit: {
          title: "$t(deposit1)",
          description1: " ",
          description2: " ",
          description3: "can be deposited.",
          depositAddressCopyMessage: "$t(depositAddressCopyMessage)",
        },
        depositAddress: {
          searchPlaceholder: "$t(searchUserAndIDAndAddress)",
          verifyTargetEthWallet: "This wallet is an ETH wallet.",
          verifyDuplicateLabel: "$t(verifyDuplicateLabel)",
        },
        policy: {
          title: "$t(security)",
          description:
            "You can securely manage assets with wallet-specific security policy.",
          policyLabel: "$t(withdrawalLimit)",
          allowedAddressLabel: "$t(withdrawAddressWhitelist)",
          active: "Activated",
          inactive: "Deactivated",
        },
        setting: {
          walletName: "$t(walletName)",
          walletId: "Wallet ID",
          saveButton: "$t(save)",
          walletPassword: "$t(walletPassword)",
          oldPassword: "$t(oldPassword)",
          newPassword: "$t(newPassword)",
          newPasswordInput: "$t(newPasswordInput)",
          newPasswordInputConfirm: "$t(newPasswordConfirmInput)",
          forgottenPassword: "Forgotten your wallet password?",
          walletNameCompleteTitle: "Wallet name successfully changed",
          walletNameCompleteDescription: "A new wallet name has been set.",
          walletPasswordCompleteTitle: "Password change complete",
          walletPasswordCompleteDescription: "$t(setNewWalletPasswordMessage)",
          forgottenPasswordCompleteTitle:
            "Wallet password successfully recovered",
          forgottenPasswordCompleteDescription:
            "$t(setNewWalletPasswordMessage)",
          authButton: "$t(authButton)",
          closeButton: "$t(close)",
          verifyDuplicateWalletName: "There is a duplicate wallet name.",
          verifyWrongPassword: "$t(verifyWrongPassword)",
          verifyOldAndNewSame:
            "The new password is the same as the old password. Please enter a different password.",
          verifyNewAndNewConfirmDiff: "$t(verifyNewAndNewConfirmDiff)",
        },
        smartcontract: {
          searchPlaceholder: "Search smart contract address / transaction hash",
          retryGasPriceCompleteMessage: "$t(retryGasPriceCompleteMessage)",
          copyAddressCompleteMessage: "$t(copyMessage)",
          copyWalletNameCompleteMessage: "$t(copyWalletAddressMessage)",
        },
        transfer: {
          coinAndTokenEmptyLabel:
            "There are no coins/tokens available for withdrawal.",
          searchPlaceholder: "Search by address / transaction hash",
          retryGasPriceCompleteMessage: "$t(retryGasPriceCompleteMessage)",
          copyAddressCompleteMessage: "$t(copyMessage)",
          copyWalletNameCompleteMessage: "$t(copyWalletAddressMessage)",
        },
        user: {
          searchPlaceholder: "$t(searchUserAndIDAndAddress)",
          btcWalletMessage: "This wallet is a BTC wallet.",
          verifyDuplicateLabel: "$t(verifyDuplicateLabel)",
        },
        withdraw: {
          isNotRegisterWhitelistAddress:
            "This address is not registered in the whitelist.",
          notExistsCoin: "No coin exists.",
          notExistsTransaction: "No transaction exists.",
          withdrawCompleteTitle: "Withdrawal request completed",
          withdrawCompleteSubmitButton: "Check the withdrawal history",
          copyAddressCompleteMessage: "$t(copyMessage)",
          copyWalletNameCompleteMessage: "$t(copyWalletAddressMessage)",
        },
      },
    },
    wallet: {
      recover: {
        guide: {
          title: "$t(setRecoverkitInfo)",
          description1:
            "Use the recovery kit you have downloaded upon creating the wallet, ",
          description2: "copy the data in area D",
          description3:
            " and paste it in the text box below.\nIf you are keeping a printed version, use a QR Code reader on \nD area to read the QR Code.",
          encryptedPassphrasePlaceholder: "Enter the data in D area.",
          submitButton: "$t(next)",
        },
        index: {
          title: "Recover wallet password",
          step1Name: "$t(setRecoverkitInfo)",
          step2Name: "$t(setNewPassword)",
          step3Name: "$t(setOTP)",
        },
        otp: {
          submitButton: "$t(authButton)",
          previousButton: "$t(previousStep)",
        },
        passphrase: {
          title: "$t(setNewPassword)",
          description: "Enter new wallet password to use.",
          passwordPlaceholder: "$t(newPasswordInput)",
          confirmPasswordPlaceholder: "$t(newPasswordConfirmInput)",
        },
      },
      register: {
        index: {
          title: " Create master wallet",
          step1Name: "$t(setName)",
          step2Name: "$t(setWalletPassword)",
          step3Name: "Download recovery kit",
        },
        name: {
          title: "$t(setName)",
          description: "Set a name for the new wallet.",
          walletName: "$t(walletName)",
          submitButton: "$t(next)",
        },
        passphrase: {
          title: "$t(setWalletPassword)",
          description:
            "Set a password for the new Master Wallet you are creating.\nA password is required for wallet deposit/withdrawal creating a user wallet.",
          passwordPlaceholder: "Enter password",
          confirmPasswordPlaceholder: "Re-enter password",
        },
        recoverkit: {
          title: "Download and store recovery kit",
          description1:
            "Please download recovery kit with key file in case of loss.\nIf you lose the recovery kit, ",
          description2: "$t(cannotRecoverWallet)",
          description3: " will be done.",
          pdfDownloadButton: "Download",
          pdfDescription1: "Print and store in a secure place",
          pdfDescription2:
            "Risk of hacking if kept on computer. So be sure to print it out \nand keep it in a secure place out of reach of others.",
          pdfDescription3: "It was printed and stored in a secure place.",
          pdfDescription4: "Delete from the download folder",
          pdfDescription5:
            "For security, please delete the recovery kit file from the download folder.",
          pdfDescription6: "The file was deleted from the download folder.",
          previousButton: "$t(previousStep)",
          createWalletButton: "$t(createMasterWallet1)",
          createWalletFailedMessage:
            "Failed to create wallet. Please try again.",
        },
      },
      error: {
        USER_INFO_NOT_FOUND: "User information does not exist.",
      },
    },
    member: {
      error: {
        duplicatedEmail: {
          description: " is already registered as a member.",
        },
        alreadyInvitedAccountByDifferentOrganization: {
          description: " belongs to a different organization.",
        },
      },
      emailResend: "The invitation email has been resent.",
      inviteCanceled: "The invitation has been canceled.",
    },
  },
  amountStatusBadge: {
    expected: "EST",
    applyEvent: "EVENT",
  },
  dateToolbar: {
    month: "month",
  },
  transferAddressText: {
    toDeposit: "Deposit to ",
    toWithdraw: "Withdraw to ",
    fromDeposit: "Deposit from ",
  },
  totalCountText: {
    total: "$t(total)",
    count: " $t(count)",
    person: " people",
  },
  withdrawPolicyTable: {
    columns: {
      coinAndToken: "$t(coinToken)",
      walletName: "$t(wallet)",
      oneDayWithdrawAmount: "$t(oneDaysWithdrawalLimit)",
      oneTimeWithdrawAmount: "$t(oneTimeWithdrawalLimit)",
      change: "Change",
    },
    addPolicyButton: "Add coin/token",
  },
  userWalletTable: {
    columns: {
      name: "$t(name)",
      id: "$t(ID)",
      address: "$t(address)",
    },
    rows: {
      status: {
        creating: "$t(creating)",
        inactive: "$t(creating)",
        failed: "Creation failed",
      },
      addressRefresh: "Retry",
    },
    addUserWalletButton: "$t(createUserWallet)",
  },
  transferTable: {
    userWalletMenusName: "Move to the wallet",
    masterWalletMenusName: "Move to the master wallet",
    columns: {
      createdAt: "$t(time)",
      sendUser: "$t(wallet)",
      receiveUser: "$t(content)",
      balance: "$t(amount)",
      txHash: "$t(txHash)",
      status: "$t(status)",
    },
    onlyMasterWalletName: "View only the master wallet history",
    onlyMasterWalletNameTooltip:
      "You can check the deposit and withdrawal details of this master wallet excluding user wallets.",
  },
  smartContractTable: {
    columns: {
      createdAt: "$t(time)",
      address: "Smart contract address",
      txHash: "$t(txHash)",
      status: "$t(status)",
    },
  },
  gasFeeUsageTable: {
    columns: {
      createdAt: "$t(time) $t(utc)",
      wallet: "$t(wallet)",
      content: "$t(content)",
      amount: "Usage fee",
      txHash: "$t(txHash)",
      status: "$t(status)",
    },
    exportCSVButton: "CSV로 내보내기",
  },
  depositAddressTable: {
    columns: {
      name: "$t(name)",
      id: "$t(ID)",
      address: "$t(address)",
    },
    addDepositAddressButton: "$t(createDepositAddress)",
  },
  balanceTable: {
    columns: {
      type: "Type",
      name: "$t(name)",
      userWalletSum: "$t(amount)",
      masterWallet: "$t(masterWallet)",
      userWallet: "Sum of user wallets",
      sum: "$t(total)",
    },
    myCoinName: "Show only coins held",
  },
  authorityTable: {
    me: "me",
    columns: {
      name: "$t(name)",
      email: "$t(emailAddress)",
      role: "Role",
      loginIp: "$t(ipAddress1)",
      loginIpTooltip:
        "A list of IP addresses that can log in after email authentication.",
    },
    rows: {
      loginIps: " and ",
      loginIpCount: " others",
    },
    addMemberButton: "Add team member",
  },
  authorityInfoByRoleTable: {
    columns: {
      role: "Item",
      admin: "Admin",
      spender: "Spender",
      viewer: "Viewer",
    },
    rows: {
      transfer: "View deposit and withdrawal history and balance",
      gasFee: "View and recharge gas fee",
      deposit: "Wallet deposit",
      limitWithdraw: "$t(lessThanWithralLimit)",
      approvalLimitWithdraw: "Approve/cancel withdrawal over the limit",
      limitWithdrawSetting: "Set wallet withdrawal limit",
      createMasterWallet: "$t(createMasterWallet)",
      createDepositAddressAndUserWallet: "Create deposit address/user wallet",
      smartContractCall: "Call smart contract function",
      changePassword: "Change/recover wallet password",
      changeTeamAuth: "Change team member privilege",
      apiSecret: "Reissue API Secret",
    },
    footerTitle: " Currently supported by API only.",
  },
  allowedIpTable: {
    columns: {
      name: "$t(name)",
      address: "$t(ipAddress1)",
      delete: "$t(delete1)",
    },
    addIpButton: "$t(addIpAddress)",
  },
  allowedAddressTable: {
    columns: {
      name: "$t(name)",
      coinAndToken: "$t(coinToken)",
      address: "$t(address)",
      delete: "$t(delete1)",
    },
    rows: {
      userNameCaption: " and all sub-user wallets",
      allCoinAndToken: "$t(allCoinAndToken)",
      userAdddressCaption: " and more",
    },
    addAddressButton: "$t(addAddress)",
  },
  balanceReportTable: {
    columns: {
      wallet: "$t(wallet)",
      coinName: "$t(coinName)",
      balance: "$t(balance)",
    },
  },
  walletTab: {
    transferTitle: "D/W history",
    balanceTitle: "Balance",
    depositTitle: "$t(deposit)",
    withdrawTitle: "$t(withdrawal)",
    settingTitle: "Setting",
    userWalletTitle: "$t(userWallet)",
    depositAddressTitle: "$t(depositAddress)",
    smartcontractTitle: "Smart contract call history",
    policyTitle: "$t(approvalPolicy)",
    memberTitle: "$t(member)",
    addressBookTitle: "$t(addressBook.label)",
  },
  gasFeeWalletTab: {
    gasFeeDepositTitle: "$t(deposit)",
    gasFeeUsageTitle: "Usage details",
  },
  accountTab: {
    accountTitle: "Account Setting",
    languageTitle: "Language",
  },
  inboxTab: {
    all: "All",
    pending: "Pending",
  },
  custodyRequestWithdrawTab: {
    allTitle: "전체",
    requestTitle: "요청",
    signingTitle: "서명 대기",
    pendingApproveTitle: "승인 대기",
    finalPendingApproveTitle: "최종 승인 대기",
  },
  needGasFeeSnackbar: {
    message1: "Insufficient gas fee wallet balance.\nThe balance must be ",
    message2: " or higher.",
    submitButton: "Deposit gas fee",
  },
  withdrawCoinSelectbox: {
    groupName: "Coins held",
    depositButton: "$t(deposit1)",
    placeholder: "$t(select)",
  },
  walletSelectbox: {
    userWalletAddressName: " and sub-user wallets",
    emptyMenuItemLabel: "There are no other wallets.",
    selectedMenuItemLabel: "$t(select)",
  },
  walletAddressSelectbox: {
    userWalletAddressName: "'s user wallet",
    emptyMenuItemLabel: "No registered address.",
    selectedMenuItemLabel: "Select address.",
  },
  dateSelectbox: {
    format: "MMMM YYYY",
  },
  coinSelectbox: {
    placeholder: "$t(select)",
  },
  depositProgress: {
    title: "Searching deposit",
  },
  createWalletLoading: {
    title: "Creating wallet",
    ethAndBtcDescription:
      "Gas fee is used to create wallet.\nIt takes about 3 minutes to complete the creation.",
    klayDescription: "Gas fee is used to create wallet.",
  },
  userWalletAddressList: {
    emptyListLabel: "No user wallet.",
  },
  dashboardLayout: {
    userAccountMenus: {
      account: "Account",
      logout: "Logout",
    },
    gasFeeAddressCopyMemssage:
      "Gas fee wallet address is copied to the clipboard.",
    depositAddressCopyMemssage: "$t(depositAddressCopyMessage)",
  },
  gasFeesInterval: {
    chargeFeeMessage: "The fee wallet has been charged.",
  },
  checkWalletsInterval: {
    activeMasterWalletMessage: "Wallet is created",
  },
  checkExpiredInterval: {
    otpSubmitName: "$t(authButton)",
    otpCancelButton: "$t(cancel)",
  },
  checkAccessTokenExpiredInterval: {
    otpSubmitName: "$t(authButton)",
    otpCancelButton: "$t(cancel)",
  },
  roleGroupIcon: {
    retrieve: "View",
    thanLessWithdraw: "$t(lessThanWithralLimit)",
    thanMoreWithdraw: "Approve withdrawal over the limit",
    walletManager: "Managing wallet",
    teamManager: "$t(teamManager)",
  },
  eventStatusIcon: {
    pendingApproval: "Pending approval",
    reject: "Rejected",
    requested: "Requested",
    pending: "Pending mining",
    mined: "Mined",
    confirmed: "Complete",
    failed: "Request failed",
    replaced: "Canceled",
    reverted: "Failed",
    internalReverted: "Failed",
  },
  setPasswordForm: {
    confirmPasswordError: "$t(verifyNewAndNewConfirmDiff)",
    passwordLabel: "$t(walletPassword)",
    previousButton: "$t(previousStep)",
    nextButton: "$t(next)",
  },
  otpForm: {
    title: "$t(setOTP)",
    description:
      "Please enter the 6-digit authentication code shown on the Google Authenticator.",
    otpLabel: "OTP Code",
    otpPlaceholder: "$t(sixDigits)",
  },
  otpGuideForm: {
    title: "Set OTP",
    description1: "OTP setting is required for the first login. \n",
    description2: "Please scant the QR Code on Google Authenticator.",
    description3: "",
    androidDownloadButton: "Download for Android phone",
    iosDownloadButton: "Download for iPhone",
    nextButton: "$t(next)",
    account: "Account",
    key: "Key",
    inputGuideDescription:
      "1. Click the [+] button \n2. Select [Enter Setup Key] \n3. Enter the account and key values at the top.",
    qrcode: "QR Code",
    input: "Enter Manually",
  },
  depositAddressForm: {
    depositAddress: "$t(depositAddress)",
    depositAddressTooltip: "Copy deposit address",
    qrCode: "$t(qrCode)",
    depositWarningTitle: "$t(depositWarning)",
    closeButton: "$t(close)",
  },
  withdrawForm: {
    title: "$t(withdrawal)",
    toLabel: "$t(receivedAddress)",
    toAddressLabel: "Select address",
    toInputLabel: "$t(directInput)",
    toCoinLabel: "$t(sendingCoin)",
    emptyCoinLabel: "No coins held.",
    amountPlaceholder: "Enter amount",
    submitButton: "$t(withdrawal)",
    btcAmountWarning: "Insufficient balance available for withdrawal.",
    ethAndKlayAmountWarning: "Insufficient balance.",
    eosAmountWarning: "Insufficient balance.",
    minWithdrawableAmount:
      "The coin {{name}} can only be withdrawn if the amount is at least {{amount}} {{symbol}}.",
    balanceTooltip:
      "This is the current withdrawable balance. It does not include amounts pending approval, awaiting processing, or in progress.",
  },
  withdrawCalculatorForm: {
    title: "$t(willWithdrawAmount)",
    withdrawAmountLabel: "$t(withdrawAmount)",
  },
  btcWithdrawCalculatorForm: {
    title: "$t(willWithdrawAmount)",
    withdrawAmountLabel: "$t(withdrawAmount)",
    estimatedFeeLabel: "$t(expectedFeeAmount)",
    withdrawTotalAmountLabel: "Total Amount to send",
  },
  gasFeeDepositForm: {
    title: "Deposit fees",
    description1: "You can deposit ",
    description2: ".",
    minimalDepositTitle: "$t(minimalExpectedAmount)",
    minimalDepositTooltip: "$t(minimalExpectedAmountMessage)",
    myGasFeeTitle: "$t(myGasFee)",
    gasFeePayment: "$t(gasFeePayment)",
    neededGasFeeWarning: "The fee is insufficient.",
    depositAddressTitle: "$t(depositAddress)",
    depositAddress: "$t(address)",
    depositQRCode: "$t(qrCode)",
    depositAddressCopyTooltip: "$t(gasFeeWalletAddressCopy)",
    gasFeeWarningTitle: "$t(depositWarning)",
  },
  withdrawPolicyEmptyStates: {
    title: "Set a withdrawal limit",
    description:
      "Administrator can set the master and user wallets’\nwithdrawal limit.",
    descriptionForBitcoin:
      "Administrator can set a withdrawal limit of a wallet.",
    submitButton: "Add coin",
  },
  walletEmptyStates: {
    title: "There is no wallet yet",
    descriptionForViewer: "Only the administrator can create master wallets.",
    description: "Create a new master wallet.",
    submitButton: "Make a master wallet",
  },
  userWalletsEmptyStates: {
    ethAndKlay: {
      title: "There is no issued user wallet.",
      description: "Create a new user wallet.",
      submitButton: "$t(createUserWallet)",
    },
    btc: {
      title: "There is no issued deposit address.",
      description: "Create a new deposit address.",
      submitButton: "$t(createDepositAddress)",
    },
  },
  transferEmptyStates: {
    title: "There is no deposit and withdrawal history",
    description: "$t(depositCoinAndToken)",
    submitButton: "$t(deposit1)",
  },
  smartContractEmptyStates: {
    title: "There is no smart contract call history",
    description: "Call smart contract function with SDK or API.",
    submitButton: "Check the document",
  },
  searchEmptyStates: {
    title: "No results found.",
    description: "Please search again with different conditions.",
    submitButton: "Reset",
  },
  requestWithdrawEmptyStates: {
    title: "There is no withdrawal request.",
    description: "Withdrawals requiring approval are displayed.",
  },
  registerWalletNeededGasFeeEmptyStates: {
    title: "$t(failedCreateWallet)",
    description:
      "Failed to create wallet due to insufficient gas fee.\nPlease try again after recharging gas fee.",
    submitButton: "$t(chargeGasFee)",
  },
  registerWalletFailedEmptyStates: {
    title: "$t(failedCreateWallet)",
    description1:
      "Failed to create wallet. Please try again.\nIf the issue persists",
    description2: "please contact us at.",
    submitButton: "Retry",
  },
  gasFeeUsageEmptyStates: {
    title: "No usage history",
    description: "Please deposit coin to fee wallet",
    submitButton: "$t(deposit1)",
  },
  balanceEmptyStates: {
    title: "No balance",
    description: "$t(depositCoinAndToken)",
    submitButton: "$t(deposit1)",
  },
  allowedIpListEmptyStates: {
    title: "There is no registered IP address.",
    description: "Please register IP address to allow API calls.",
    submitButton: "$t(addIpAddress)",
  },
  allowedIpInactiveListEmptyStates: {
    title: "$t(inactiveWhitelist)",
    description:
      "Once activated, API calls are allowed only from authorized IP addresses.",
    submitButton: "$t(activate)",
  },
  allowedAddressListEmptyStates: {
    title: "$t(inactiveWhitelist)",
    description:
      "Once activated, withdrawals can only be made to allowed addresses.",
    submitButton: "$t(activate)",
  },
  registerAllowedIpEmail: {
    title: "A new IP address has been listed \non the whitelist.",
    description1: "Hi, ",
    description2:
      ".\nA new IP address has been registered on the whitelist.\nYou can start API calls from the IP address.",
    registerAt: "$t(time)",
    username: "$t(username)",
    registeredIpAddress: "$t(registeredIpAddress)",
    registerAllowedIpWarning: {
      ifNotDirectly: "$t(ifAbnormalAct)",
      removeIpAddress:
        "Please delete the IP address immediately from the dashboard.",
      ifHelp: "$t(ifHelp)",
      contact1: "$t(contact) ",
      contact2: "",
    },
    confirmButton: "$t(confirmDashbord)",
  },
  registerAllowedAddressEmail: {
    title: "A new withdrawal address has been listed on the whitelist.",
    description1: "Hi, ",
    description2:
      ".\nA new withdrawal address has been registered on the whitelist.\nYou can start withdrawals to the address.",
    registerAt: "$t(time)",
    username: "$t(username)",
    targetWalletName: "$t(targetWallet)",
    addressName: "$t(addressName)",
    coinToken: "$t(coinToken)",
    walletAddress: "$t(address)",
    registerAllowedAddressWarning: {
      ifNotDirectly: "$t(ifAbnormalAct1)",
      removeWithdrawAddress:
        "Please delete the withdrawal address immediately from the dashboard.",
      ifHelp: "$t(ifHelp)",
      contact1: "$t(contact) ",
      contact2: "",
    },
    confirmButton: "$t(confirmDashbord)",
  },
  notAllowedIpCallEmail: {
    title: "Blocked an unauthorized IP address \nfrom attempting to call API.",
    description1: "Hi, ",
    description2: ".\n",
    highlightDescription:
      "API call was attempted from an unauthorized IP address",
    description3:
      " and blocked.\nIf it was a normal attempt, please register the IP address on the dashboard.",
    registerAt: "$t(time)",
    ipAddress: "$t(ipAddress)",
    location: "$t(location)",
    ipAccessTokenWarning: {
      ifNotDirectly: "$t(ifAbnormalAct1)",
      refreshAccessToken: "$t(reissueAccessToken)",
      ifHelp: "$t(ifHelp)",
      contact1: "$t(contact) ",
      contact2: "",
    },
    confirmButton: "Go to confirm the IP address whitelist",
  },
  notAllowedAddressCallEmail: {
    title:
      "Withdrawal was attempted from an address that is not whitelisted and blocked.",
    description1: "Hi, ",
    description2: ".\n",
    highlightDescription: "Withdrawal attempted from address not whitelisted",
    description3:
      "has been blocked.\nIf it was a normal attempt, please register the IP address on the dashboard.",
    withdrawTitle: "$t(withdrawInfo)",
    registerAt: "$t(time)",
    username: "$t(username)",
    withdrawMethod: "$t(withdrawMethod)",
    coinTokenName: "$t(coinTokenName)",
    targetWalletName: "$t(targetWallet)",
    toAddress: "$t(receivedAddress)",
    count: "$t(amount)",
    withdrawAccessTokenWarning: {
      ifNotDirectlyWithdraw: "$t(ifAbnormalAct2)",
      refreshAccessToken: "$t(reissueAccessToken)",
      ifHelp: "$t(ifHelp)",
      contact1: "$t(contact) ",
      contact2: "",
    },
    confirmButton: "Go to confirm the withdrawal address whitelist",
  },
  limitedRequestWithdrawEmail: {
    title: "A withdrawal request over the limit has occurred.",
    description1: "Hi, ",
    description2:
      ".\nA withdrawal over the limit has occurred.\nIf it is a normal withdrawal request, approve the request on the dashboard.",
    withdrawTitle: "$t(withdrawInfo)",
    registerAt: "$t(time)",
    requester: "$t(requester)",
    coinTokenName: "$t(coinTokenName)",
    withdrawWalletName: "$t(withdrawWallet)",
    toAddress: "$t(receivedAddress)",
    count: "$t(amount)",
    expectedFeeAmount: "$t(expectedFeeAmount)",
    requestWithdrawWarning: {
      ifNotDirectlyWithdraw: "$t(ifAbnormalAct2)",
      rejectRequestWithdraw:
        "Immediately reject the withdrawal request on the dashboard.",
      ifHelp: "$t(ifHelp)",
      contact1: "$t(contact) ",
      contact2: "",
    },
    confirmButton: "confirm withdrawal request",
  },
  deleteAllowedIpEmail: {
    title: "The IP address has been deleted from the whitelist.",
    description1: "Hello, ",
    description2:
      ".\nThe IP address has been deleted from the whitelist.\nFrom now on, API calls from the IP address will be blocked.",
    registerAt: "$t(time)",
    username: "$t(username)",
    deletedIpAddress: "Deleted IP address",
    confirmButton: "$t(confirmDashbord)",
  },
  deleteAllowedAddressEmail: {
    title: "The withdrawal address has been deleted from the whitelist.",
    description1: "Hello, ",
    description2:
      ".\nThe withdrawal address has been deleted from the whitelist.\nFrom now on, the address cannot make withdrawals.",
    registerAt: "$t(time)",
    username: "$t(username)",
    targetWalletName: "$t(targetWallet)",
    addressName: "$t(addressName)",
    coinToken: "$t(coinToken)",
    walletAddress: "$t(address)",
    confirmButton: "$t(confirmDashbord)",
  },
  allowedIpOnEmail: {
    title: "IP address whitelisting has been activated.",
    description:
      "Greetings, .\nIP address whitelisting has been activated.\nFrom now on, API calls can only be made from authorized IP addresses.",
    registerAt: "$t(time)",
    username: "$t(username)",
    confirmButton: "$t(confirmDashbord)",
  },
  allowedIpOffEmail: {
    title: "IP address whitelisting has been deactivated.",
    description1: "Hello, ",
    description2:
      ".\nIP address whitelisting has been deactivated.\nFrom now on, API calls can be made from all IP addresses.",
    registerAt: "$t(time)",
    username: "$t(username)",
    allowedIpWarning: {
      ifNotDirectlyActivity: "$t(ifAbnormalAct)",
      allowedIp:
        "Activate IP address whitelisting immediately from the dashboard.",
      ifHelp: "$t(ifHelp)",
      contact1: "$t(contact) ",
      contact2: "",
    },
    confirmButton: "$t(confirmDashbord)",
  },
  allowedAddressOnEmail: {
    title: "Withdrawal address whitelisting has been activated.",
    description1: "Hi, ",
    description2:
      ".\nWithdrawal address whitelisting has been activated.\nFrom now on, withdrawals can only be made to whitelist-registered addresses.",
    registerAt: "$t(time)",
    walletName: "$t(wallet)",
    confirmButton: "$t(confirmDashbord)",
  },
  allowedAddressOffEmail: {
    title: "Withdrawal address whitelisting has been deactivated.",
    description1: "Hi, ",
    description2:
      ".\nWithdrawal address whitelisting has been deactivated.\nNow withdrawals can be made to all addresses.",
    registerAt: "$t(time)",
    walletName: "$t(wallet)",
    allowedAddressWarning: {
      ifNotDirectlyActivity: "$t(ifAbnormalAct)",
      allowedAddress:
        "Activate withdrawal address whitelisting of the wallet immediately from the dashboard.",
      ifHelp: "$t(ifHelp)",
      contact1: "$t(contact) ",
      contact2: "",
    },
    confirmButton: "$t(confirmDashbord)",
  },
  verifyAddressEmail: {
    title: "Login was attempted from a new IP address.",
    description1: "Hello, ",
    description2:
      ".\nAttempted login from a new IP address has been blocked.\nTo unblock the login, please verify the IP address.",
    loginLabel: "$t(loginLabel)",
    loginAt: "$t(time)",
    ipAddress: "$t(ipAddress)",
    browser: "$t(browser)",
    location: "$t(location)",
    loginWarning: {
      ifNotDirectlyLogin: "If you did not log in",
      changePassword: "change your account password immediately for security.",
      ifHelp: "$t(ifHelp)",
      contact1: "$t(contact) ",
      contact2: "",
    },
    verifyAddressButton: "Authenticate IP address",
  },
  custodyRequestWithdrawEmail: {
    title: "커스터디 출금이 요청되었습니다.",
    description1: "",
    description2:
      "님, 안녕하세요. \n고객사로부터 커스터디 출금이 요청되었습니다.",
    withdrawTitle: "$t(withdrawInfo)",
    requestWithdrawName: "$t(requestWithdraw)",
    withdrawWalletName: "$t(withdrawWallet)",
    toAddress: "$t(receivedAddress)",
    amount: "$t(amount)",
    requestWithdrawWarning: {
      ifNotDirectlyWithdraw: "$t(ifAbnormalAct)",
      ifHelp: "$t(ifHelp)",
      contact1: "",
      contact2: "$t(contact)",
    },
    confirmButton: "커스터디 출금 요청 목록 확인하기",
  },
  custodyRequestWithdrawApproveEmail: {
    title: "커스터디 출금이 승인되었습니다. (1/2)",
    description1: "",
    description2: "님, 안녕하세요. \n커스터디 출금이 승인 되었습니다.",
    withdrawTitle: "$t(withdrawInfo)",
    approveName: "승인",
    requestWithdrawName: "$t(requestWithdraw)",
    withdrawWalletName: "$t(withdrawWallet)",
    toAddress: "$t(receivedAddress)",
    amount: "$t(amount)",
    requestWithdrawWarning: {
      ifNotDirectlyWithdraw: "$t(ifAbnormalAct)",
      ifHelp: "$t(ifHelp)",
      contact1: "",
      contact2: "$t(contact)",
    },
    confirmButton: "커스터디 출금 요청 목록 확인하기",
  },
  custodyRequestWithdrawQRCodeEmail: {
    title: "커스터디 출금 요청 QR 코드가 발급되었습니다.",
    description1: "",
    description2:
      "님, 안녕하세요. \n커스터디 출금 요청 QR 코드가 발급되었습니다.",
    withdrawTitle: "$t(withdrawInfo)",
    qrCodeName: "QR 코드 생성",
    requestWithdrawName: "$t(requestWithdraw)",
    withdrawWalletName: "$t(withdrawWallet)",
    toAddress: "$t(receivedAddress)",
    amount: "$t(amount)",
    requestWithdrawWarning: {
      ifNotDirectlyWithdraw: "$t(ifAbnormalAct)",
      ifHelp: "$t(ifHelp)",
      contact1: "",
      contact2: "$t(contact)",
    },
    confirmButton: "커스터디 출금 요청 목록 확인하기",
  },
  custodyRequestWithdrawApproveCompleteEmail: {
    title: "커스터디 출금 승인이 완료 되었습니다.",
    description1: "",
    description2: "님, 안녕하세요. \n커스터디 최종 출금 승인이 완료되었습니다.",
    withdrawTitle: "$t(withdrawInfo)",
    approveName: "최종 승인",
    requestWithdrawName: "$t(requestWithdraw)",
    withdrawWalletName: "$t(withdrawWallet)",
    toAddress: "$t(receivedAddress)",
    amount: "$t(amount)",
    requestWithdrawWarning: {
      ifNotDirectlyWithdraw: "$t(ifAbnormalAct)",
      ifHelp: "$t(ifHelp)",
      contact1: "",
      contact2: "$t(contact)",
    },
    confirmButton: "커스터디 출금 내역 확인하기",
  },
  custodyRegisterWalletEmail: {
    title: "커스터디 지갑 생성이 요청되었습니다.",
    description1: "",
    description2:
      "님, 안녕하세요. \n고객사로부터 커스터디 지갑 생성이 요청되었습니다.",
    registerRequestName: "생성 요청",
    registerWalletName: "생성할 지갑",
    requestWithdrawWarning: {
      ifNotDirectlyWithdraw: "$t(ifAbnormalAct)",
      ifHelp: "$t(ifHelp)",
      contact1: "",
      contact2: "$t(contact)",
    },
    confirmButton: "커스터디 지갑 생성 요청 목록 확인하기",
  },
  custodyRegisterWalletApproveEmail: {
    title: "커스터디 지갑 생성이 승인되었습니다. (2/2)",
    description1: "",
    description2: "님, 안녕하세요. \n커스터디 지갑 생성이 승인되었습니다.",
    approveName: "승인",
    registerRequestName: "생성 요청",
    registerWalletName: "생성할 지갑",
    requestWithdrawWarning: {
      ifNotDirectlyWithdraw: "$t(ifAbnormalAct)",
      ifHelp: "$t(ifHelp)",
      contact1: "",
      contact2: "$t(contact)",
    },
    confirmButton: "커스터디 지갑 생성 요청 목록 확인하기",
  },
  custodyRegisterWalletQRCodeEmail: {
    title: "커스터디 지갑 생성 요청 QR 코드가 발급되었습니다.",
    description1: "",
    description2:
      "님, 안녕하세요. \n커스터디 지갑 생성 요청 QR 코드가 발급되었습니다.",
    qrCodeName: "QR 코드 생성",
    registerRequestName: "생성 요청",
    registerWalletName: "생성할 지갑",
    requestWithdrawWarning: {
      ifNotDirectlyWithdraw: "$t(ifAbnormalAct)",
      ifHelp: "$t(ifHelp)",
      contact1: "",
      contact2: "$t(contact)",
    },
    confirmButton: "커스터디 지갑 생성 요청 목록 확인하기",
  },
  custodyRegisterWalletApproveCompleteEmail: {
    title: "커스터디 지갑 생성 최종 승인이 완료 되었습니다.",
    description1: "",
    description2:
      "님, 안녕하세요. \n커스터디 지갑 생성 최종 승인이 완료되었습니다.",
    approveName: "최종 승인",
    registerRequestName: "생성 요청",
    registerWalletName: "생성할 지갑",
    requestWithdrawWarning: {
      ifNotDirectlyWithdraw: "$t(ifAbnormalAct)",
      ifHelp: "$t(ifHelp)",
      contact1: "",
      contact2: "$t(contact)",
    },
    confirmButton: "커스터디 지갑 생성 내역 확인하기",
  },
  walletPolicyCard: {
    title: "Setting",
  },
  allowedAddressActiveCompleteDialog: {
    title: "$t(completeActiveWhitelist)",
    description:
      "From now on, withdrawals can only be made to the registered address.\nPlease deactivate whitelisting to lift the withdrawal restriction.",
  },
  allowedAddressActiveConfirmDialog: {
    title: "$t(isActiveWhitelisting)",
    description1: "If you activate ",
    description2: "withdrawals can be made only to",
    description3: "permitted addresses.\nAre you sure to activate?",
    closeButton: "$t(close)",
    submitButton: "$t(activate)",
  },
  allowedAddressInactiveCompleteDialog: {
    title: "$t(completeInactiveWhitelist)",
    description: "From now on, you can withdraw to all addresses.",
  },
  allowedAddressInactiveConfirmDialog: {
    title: "$t(isInactiveWhitelisting)",
    description1: "If you deactivate, from now on, ",
    description2: "withdrawals can be made to",
    description3: "all addresses.\nAre you sure to deactivate?",
    closeButton: "$t(close)",
    submitButton: "$t(inactive)",
  },
  deleteAllowedAddressCompleteDialog: {
    title: "Address deletion complete",
    description: "From now on, withdrawals cannot be made to the address.",
  },
  deleteAllowedAddressConfirmDialog: {
    title: "Will you delete the address?",
    description: "Delete .",
    closeButton: "$t(close)",
    submitButton: "$t(delete)",
  },
  registerAllowedAddressCompleteDialog: {
    title: "Address registration complete",
    description: "From now on, you can withdraw to the address.",
  },
  registerAllowedAddressDialog: {
    title: "$t(addAddress)",
    directInputLabel: "$t(directInput)",
    selectedWalletLabel: "Select wallet",
    nameLabel: "$t(name)",
    coinAndTokenLabel: "$t(coinToken)",
    emptyCoinLabel: "$t(emptyCoin)",
    addressLabel: "$t(address)",
    walletLabel: "$t(wallet)",
    closeButton: "$t(close)",
    addButton: "$t(addButton)",
  },
  allowedIpActiveCompleteDialog: {
    title: "$t(completeActiveWhitelist)",
    description: "$t(availableCurrentRegisteredIp)",
  },
  allowedIpInactiveCompleteDialog: {
    title: "$t(completeInactiveWhitelist)",
    description: "From now on, API calls can be made from all IP addresses.",
  },
  allowedIpInactiveConfirmDialog: {
    title: "$t(isInactiveWhitelisting)",
    description1: "If you deactivate ",
    description2: "all IP addresses can make",
    description3: "API calls.\nAre you sure to deactivate?",
    closeButton: "$t(close)",
    submitButton: "$t(inactive)",
  },
  deleteAllowedIpCompleteDialog: {
    title: "IP address successfully deleted",
    description: "From now on, API calls from the IP address will be blocked.",
  },
  deleteAllowedIpConfirmDialog: {
    title: "Do you want to delete the IP address?",
    description: " will be deleted.",
    closeButton: "$t(close)",
    submitButton: "$t(delete)",
  },
  registerAllowedIpCompleteDialog: {
    title: "IP address successfully registered",
    description: "From now on, API calls can be made from the IP address.",
  },
  registerAllowedIpDialog: {
    title: "$t(addIpAddress)",
    description: "You can register IP addresses allowed to make API calls.",
    nameLabel: "$t(name)",
    ipAddressLabel: "$t(ipAddress1)",
    ipAddressPlaceholder: "IPv4 address (0.0.0.0~255.255.255.255)",
    closeButton: "$t(close)",
    submitButton: "Register",
  },
  registerAndAllowedIpActiveCompleteDialog: {
    title: "Whitelisting activation and address registration complete",
    description: "$t(availableCurrentRegisteredIp)",
  },
  registerAndAllowedIpActiveConfirmDialog: {
    title: "$t(isActiveWhitelisting)",
    description1:
      "Once activated, API calls from unregistered IP addresses will be blocked.\nIf wallet-synced applications are already calling APIs,\nplease activate after IP address registration. Otherwise ",
    description2: "the application's oper\n",
    description3: "ation may",
    description4: "stop.\n          \nDo you want to activate?",
    closeButton: "$t(close)",
    submit1Button: "Activate after registering IP address",
    submit2Button: "$t(activate)",
  },
  createAccessTokenCompleteDialog: {
    title: "Access Token creation complete",
    description: "A new Access Token has been created.",
    submitButton: "$t(confirm)",
  },
  createAccessTokenDialog: {
    title: "Create Access Token",
    description:
      "Please set the validity period of the Access Token you want to create.",
    expiresInLabel: "Validity period (sec)",
  },
  createApiSecretCompleteDialog: {
    title: "API Secret reissuance complete",
    description: "A new API Secret has been created.",
    submitButton: "$t(confirm)",
  },
  refreshAccessTokenDialog: {
    title: "Reissue Access Token",
    description:
      "The previously created Access Token can no longer be used.\nDo you want to reissue?",
    submitButton: "$t(reissue)",
  },
  refreshApiSecretDialog: {
    title: "Reissue API Secret",
    description:
      "The API Secret created before can no longer be used.\nDo you want to reissue?",
    submitButton: "$t(reissue)",
  },
  authorityInfoByRoleDialog: {
    title: "Authority Info",
    description: "Authority available by role is as follows.",
    submitButton: "$t(confirm)",
  },
  authorityIpAddressesDialog: {
    ipAddressLabel: "$t(ipAddress1)",
    createdAtLabel: "Registration Time $t(utc)",
    submitButton: "$t(confirm)",
  },
  createAuthorityDialog: {
    title: "Do you want to add team members?",
    description: "$t(contactEmail)",
    submitButton: "$t(confirm)",
  },
  deleteAuthorityCompleteDialog: {
    title: "Authority successfully removed",
    description: "Authority has been successfully removed.",
    submitButton: "$t(confirm)",
  },
  deleteAuthorityDialog: {
    title: "Remove authority",
    description: "Remove the authority of .",
    closeButton: "$t(close)",
    submitButton: "$t(delete)",
  },
  updateAuthorityCompleteDialog: {
    title: "Authority change complete",
    description: "Authority has been successfully changed.",
    submitButton: "$t(confirm)",
  },
  updateAuthorityDialog: {
    title: "Change authority",
    description1: "Change the authority of ",
    description2: "\nas follows.",
    authorityLabel: "$t(authority)",
    closeButton: "$t(close)",
    submitButton: "$t(change)",
  },
  registerDepositAddressCompleteDialog: {
    title: "Deposit address created",
    description: " has been created.",
  },
  registerDepositAddressDialog: {
    title: "Create a new deposit address",
    description1: " Create a deposit address of the ",
    description2: " wallet.",
    nameLabel: "$t(name)",
    closeButton: "$t(close)",
    submitButton: "$t(create)",
  },
  exportGasFeeTransferDialog: {
    title: "Export fee wallet usage history",
    description: "You can download the monthly history as a CSV file.",
    warningAlert1: "During sending status or waiting for mining ",
    warningAlert2: "it is indicated in ",
    warningAlert3: "unconfirmed quantity.",
    closeButton: "$t(close)",
    submitButton: "CSV download",
  },
  smartContractDetailDialog: {
    retryGasPriceDescription: "$t(retryGasFeeMessage)",
    retryGasPriceButton: "$t(retryRealGasFee)",
    gasPriceLabel: "$t(gasPrice)",
    title: "Smart contract call details",
    createdAtLabel: "$t(time)",
    smartContractAddressLabel: "Smart contract address",
    statusLabel: "$t(status)",
    gasFeeLabel: "$t(fee)",
    transactionHashLabel: "$t(transactionHash)",
    closeButton: "$t(close)",
  },
  smartContractTransactionConfirmDialog: {
    title: "$t(retryRealGasFeeMessage)",
    description: "$t(newTransactionMessage)",
    currentGasPriceLabel: "$t(realGasFee)",
    currentGasPriceTooltipMessage: "$t(realNetworkBasedGasFee)",
    warningTitle: "$t(doNotBeSurprised)",
    warningAlert1:
      "Existing transaction with lower gas fee may be mined first.",
    warningAlert2:
      "Multiple identical transactions occur, but when one of them is mined, the remaining\ntransactions are cancelled.",
    warningAlert3: "Duplicate confirmation of transactions never occurs.",
    closeButton: "Cancel resend",
    submitButton: "Resend",
  },
  transferRetryTransactionConfirmDialog: {
    title: "$t(retryRealGasFeeMessage)",
    description: "$t(newTransactionMessage)",
    currentGasPriceLabel: "$t(realGasFee)",
    currentGasPriceTooltipMessage: "$t(realNetworkBasedGasFee)",
    warningTitle: "$t(doNotBeSurprised)",
    warningAlert1:
      "Existing transaction with lower gas fee may be mined first.",
    warningAlert2:
      "Multiple identical transactions occur, but when one of them is mined, the remaining\ntransactions are cancelled.",
    warningAlert3: "Duplicate confirmation of transactions never occurs.",
    closeButton: "Cancel resend",
    submitButton: "Resend",
  },
  transferSearchOptionDialog: {
    btcName: {
      description:
        "You can search by deposited address, name of the deposit address, deposited address\nor withdrawn address.",
    },
    btcTransactionHash: {
      description: "You can search by transaction hash.",
    },
    ethKlayName: {
      description:
        "You can search by the address to which the deposit was sent or withdrawn.",
    },
    ethKlayTransactionHash: {
      description: "You can search by transaction hash.",
    },
    eosName: {
      description:
        "You can search by the address to which the deposit was sent or withdrawn.",
    },
    eosTransactionHash: {
      description: "You can search by transaction hash.",
    },
    title: "Search option",
    description:
      "You can use the following search options.\nPartial search is currently unsupported.",
    contentLabel: "$t(content)",
    transactionHashLabel: "$t(transactionHash)",
    submitButton: "$t(confirm)",
  },
  transferStatusDialog: {
    REQUEST_PENDING: {
      description: "Waiting for approval from the owner or middle manager.",
    },
    REQUEST_REJECT: {
      description: "Approval was rejected by the owner or middle manager.",
    },
    PROCEEDING_PENDING: {
      description: "Waiting for KODA to process the request.",
    },
    PROCEEDING_REJECT: {
      description: "KODA rejected the processing of the request.",
    },
    PROCEEDING_CANCEL: {
      description: "The approver canceled the request.",
    },
    PROCEEDING: {
      description: "The request is being processed by KODA.",
    },
    TRANSFER: {
      description:
        "The transaction has been initiated and is being transferred.",
    },
    TRANSFER_COMPLETE: {
      description: "The transaction has been successfully completed.",
    },
    TRANSFER_FAILED: {
      description: "The transaction failed to transfer.",
    },
    DEPOSIT: {
      description:
        "The deposit transaction has been detected and is awaiting confirmation.",
    },
    DEPOSIT_COMPLETE: {
      description: "The deposit transaction has been confirmed.",
    },
    submitButton: "$t(confirm)",
  },
  transferWithdrawDetailDialog: {
    titleByTransferType: {
      DEPOSIT: {
        title: "Deposit details",
      },
      WITHDRAWAL: {
        title: "Withdrawal details",
      },
    },
    retryGasPriceDescription: "$t(retryGasFeeMessage)",
    retryGasPriceButton: "$t(retryRealGasFee)",
    gasPriceLabel: "$t(gasPrice)",
    createdAtLabel: "$t(time)",
    toAddressLabel: "Send to address",
    fromAddressLabel: "$t(receivedAddress)",
    amountLabel: "$t(amount)",
    statusLabel: "$t(status)",
    gasFeeLabel: "$t(fee)",
    transactionHashLabel: "$t(transactionHash)",
    closeButton: "$t(close)",
  },
  registerUserWalletCompleteDialog: {
    title: "Complete request to create user wallet",
    description: " has been created.",
  },
  registerUserWalletDialog: {
    title: "Create a new user wallet",
    description1: " Create a new user wallet under the ",
    description2: " master wallet.",
    nameLabel: "$t(name)",
    closeButton: "$t(close)",
    submitButton: "$t(create)",
  },
  withdrawCompleteDialog: {
    description1: "Withdrawal request by ",
    description2:
      " has been completed.\nIt may take some time for the transaction to be finalized.",
    closeButton: "$t(close)",
  },
  withdrawOTPDialog: {
    title: "Withdraw as below.",
    description1: "Lastly ",
    description2: "please reconfirm the address and the coin to be sent",
    description3:
      "then,\nenter a 6-digit verification code shown on the Google Authenticator.",
    fromAddressLabel: "$t(receivedAddress)",
    toCoinLabel: "$t(sendingCoin)",
    otpCodeLabel: "OTP authentication code",
    otpCodePlaceholder: "$t(sixDigits)",
    closeButton: "$t(close)",
    submitButton: "$t(withdrawal)",
  },
  withdrawPassphraseDialog: {
    normal: {
      title: "Enter wallet password",
      description: "Please enter password of .",
    },
    withdraw: {
      title: "Authenticate withdrawal",
      description1: "To authenticate withdrawal ",
      description2: "enter password of ",
      description3: ".",
    },
    passwordLabel: " Password",
    closeButton: "$t(close)",
    submitButton: "$t(authButton)",
  },
  withdrawRejectCompleteDialog: {
    title: "Withdrawal has been declined.",
    description: "The requested withdrawal has been canceled.",
    submitButton: "$t(confirm)",
  },
  withdrawRejectDialog: {
    title: "Will you decline the withdrawal?",
    description: "The requested withdrawal will be canceled.",
    cancelButton: "$t(cancel)",
    submitButton: "Decline withdrawal",
  },
  withdrawPolicyCompleteDialog: {
    title: "Withdrawal limit setting complete",
    description: "Withdrawal limit of has been set.",
    submitButton: "$t(confirm)",
  },
  withdrawPolicyInfoDialog: {
    masterWalletPolicy: {
      infoTitle: "$t(masterWallet)",
      infoList1: "$t(warningWIthdrawalLimit2)",
      infoList2: "$t(warningWithdrawalLimit1)",
    },
    userWalletPolicy: {
      infoTitle: "$t(userWallet)",
      infoList1: "$t(warningWIthdrawalLimit2)",
      infoList2: "$t(warningWithdrawalLimit1)",
      infoList3:
        "Blanket application to all user wallets below the Master Wallet.",
      infoList4: "Respective limit is applied to each user wallet.",
    },
    bitCoin: {
      infoTitle: "$t(wallet)",
      infoList1: "$t(warningWIthdrawalLimit2)",
      infoList2: "$t(warningWithdrawalLimit1)",
    },
    title: "$t(withdrawalLimit)",
    walletType: {
      masterWallet: "$t(masterWallet)",
      userWallet: "$t(userWallet)",
    },
    submitButton: "$t(confirm)",
  },
  withdrawPolicySettingDialog: {
    update: {
      title: " Change withdrawal limit",
      description1: " of ",
      description2: " Change withdrawal limit.",
      submitButton: "$t(change)",
    },
    create: {
      title: " Withdrawal limit setting",
      description1: " of ",
      description2: " Set withdrawal limit.",
      submitButton: "$t(next)",
    },
    oneDayAmountLabel: "$t(oneDaysWithdrawalLimit)",
    oneTimeAmountLabel: "$t(oneTimeWithdrawalLimit)",
    userWallet: {
      infoList1:
        "Withdrawal by team member with Spender privilege is limited up to the withdrawal limit.",
      infoList2: "$t(warningWithdrawalLimit1)",
      infoList3:
        "Blanket application to all user wallets below the Master Wallet.",
      infoList4: "Respective limit is applied to each user wallet.",
    },
    masterWallet: {
      infoList1:
        "Withdrawal by team member with Spender privilege is limited up to the withdrawal limit.",
      infoList2: "$t(warningWithdrawalLimit1)",
    },
    closeButton: "$t(close)",
  },
  accessTokenKeepDialog: {
    title: " later, you will logged out.",
    description:
      "You were automatically logged out for security as 1 hour passed after logging in.\nPlease authenticate with OTP to stay logged in.",
    logoutButton: "Log out",
    submitButton: "Stay logged-in",
  },
  changeUserNameCompleteDialog: {
    title: "User name successfully changed",
    description: "A new user name has been set.",
    submitButton: "$t(confirm)",
  },
  changeUserNameAndLanguageCompleteDialog: {
    title: "User name and language successfully changed",
    description: "A new user name and language have been set.",
    submitButton: "$t(confirm)",
  },
  changeUserPasswordCompleteDialog: {
    title: "Account password successfully changed",
    description: "A new account password has been set.",
    submitButton: "$t(confirm)",
  },
  findOTPDialog: {
    title: "$t(forgottenOTP)",
    description: "$t(contactEmail)",
    submitButton: "$t(confirm)",
  },
  firstDepositConfirmDialog: {
    title: "Are you trying to deposit gas fee?",
    description1:
      "All wallets use gas fee deposited in the gas fee wallet.\nThe gas fee",
    description2: " must be deposited into ",
    description3: "the gas fee wallet.",
    closeButton: "$t(dontShowAgain)",
    gasFeeButton: "Move to gas fee wallet",
    submitButton: "Confirmed",
  },
  gasFeeDepositDialog: {
    common: {
      title: "Please recharge fees.",
      description:
        "To create a wallet, the fee must be charged at least the minimum deposit amount.",
      statusDone: "Charging complete",
    },
    makeWallet: {
      closeButton: "$t(close)",
      submitButton: "$t(next)",
    },
    deposit: {
      title: "Recharge fee",
      description: "\n can be deposited.",
      statusDone: "Deposited",
      closeButton: "$t(close)",
    },
    retryWallet: {
      closeButton: "$t(close)",
      submitButton: "Retry wallet creation",
    },
    retryGasFeeWallet: {
      closeButton: "Cancel",
      submitButton: "$t(createMasterWallet1)",
    },
    minimalDeposit: "$t(minimalExpectedAmount)",
    minimalDepositTooltipMessage: "$t(minimalExpectedAmountMessage)",
    myGasFeeLabel: "$t(myGasFee)",
    myGasFeeWarningList: "Please deposit more than the minimum deposit amount.",
    depositAddressLabel: "$t(depositAddress)",
    depositType: {
      address: "$t(address)",
      qrCode: "$t(qrCode)",
    },
    gasFeeAddressCopy: "$t(gasFeeWalletAddressCopy)",
    depositCaption:
      "The address is a separate wallet address that is different from the master wallet address.",
    depositWarning: {
      warningTitle: "$t(depositWarning)",
    },
  },
  registerGasFeeWalletFailedDialog: {
    title: "Failed to create a wallet",
    description:
      "Wallet creation failed due to insufficient gas fee.\nPlease recharge gass fee.",
    closeButton: "Cancel wallet creation",
    submitButton: "$t(chargeGasFee)",
  },
  registerWalletBlockchainDialog: {
    title: "Make a new wallet",
    platformLabel: "Platform",
    closeButton: "$t(close)",
    submitButton: "$t(next)",
  },
  registerWalletCoinDialog: {
    title: "Select coin/token",
    description: "Select a coin to apply the withdrawal limit.",
    coinAndTokenLabel: "$t(coinToken)",
    emptyCoinAndTokenLabel: "$t(emptyCoin)",
    closeButton: "$t(close)",
    submitButton: "$t(next)",
  },
  settingCompleteDialog: {
    submitButton: "$t(confirm)",
  },
  templateCompleteDialog: {
    submitButton: "$t(confirm)",
  },
  userWalletPickerDialog: {
    closeButton: "$t(close)",
    submitButton: "Select",
  },
  walletSafePrintDialog: {
    title: "Have you stored the printed material securely?",
    description1: "If the recovery kit is lost, ",
    description2: "$t(cannotRecoverWallet)",
    description3: ", and\nHenesis will not be responsible for loss.",
    submitButton: "Yes, I confirmed.",
  },
  materialDrawer: {
    requestWithdrawName: "$t(requestWithdraw)",
    apiSyncName: "$t(apiSync)",
    teamName: "$t(teamManager)",
    walletName: "$t(wallet)",
    emptyWalletName: "There is no wallet.",
    gasFeeName: "Fee wallet",
    gasFeePaymentName: "$t(gasFeePayment)",
    balanceReport: "Total Balance",
    inboxRequests: "Received Approvals",
    outboxRequests: "Submitted Approvals",
    proceedingRequests: "All Processing History",
    organization: "Organization",
    coins: "Coin List",
  },
  requestWithdrawItem: {
    requestNameCaption1: "Request from ",
    requestNameCaption2: "",
    approveComplete: "Confirmed",
    approveButton: "Confirm",
    rejectComplete: "Rejected",
    rejectButton: "Reject",
  },
  languageSelectbox: {
    ko: "한국어",
    en: "English",
  },
  myMenu: {
    authority: "Permission Settings",
    account: "Account Settings",
    language: "Change Language",
    logout: "Logout",
  },
  requestWithdrawList: {
    column: {
      requesterAndDate: "$t(requester) / $t(time) $t(utc)",
      from: "$t(wallet)",
      to: "$t(receivedAddress)",
      amount: "$t(amount)",
      status: "$t(status)",
    },
  },
  createRequestOrganizationCompleteDialog: {
    title: "Create request completed",
    description: "Create request has been successfully submitted.",
    submitButton: "$t(confirm)",
  },
  updateRequestOrganizationCompleteDialog: {
    title: "Update request completed",
    description: "Update request has been successfully submitted.",
    submitButton: "$t(confirm)",
  },
  deleteRequestOrganizationCompleteDialog: {
    title: "Delete request completed",
    description: "Delete request has been successfully submitted.",
    submitButton: "$t(confirm)",
  },
  updateAuthorityOrganizationMemberCompleteDialog: {
    title: "Management Authority Change Complete",
    description1: "Management authority of ",
    description2: " has been updated.",
  },
  updateOrganizationMemberDialog: {
    title: "Change Member Authority",
    description: "The authority will be changed as follows.",
    authority: "Management Authority",
  },
  authorityTab: {
    title: "Management Authority",
    organizationSuperAdmin: {
      description1: "Can grant administrator roles to organization members.",
      description2:
        "Has all permissions of Wallet Administrators and Member Administrators.",
    },
    organizationWalletsAdmin: {
      description1: "Can create wallets.",
      description2: "Holds the Owner role for all wallets.",
    },
    organizationUsersAdmin: {
      description1: "Can add and remove members in the organization.",
      description2: "Cannot grant administrator roles to organization members.",
    },
  },
  walletRoleTab: {
    title: "Wallet Roles",
    changeRole: "Change Role",
    empty: "No associated wallets.",
    walletOwner: {
      description: "Responsible for approvals within the wallet.",
    },
    walletManager: {
      description: "Responsible for intermediate approvals within the wallet.",
    },
    walletMember: {
      description:
        "Can request approval after withdrawals and address book registrations/deletions.",
    },
    walletViewer: {
      description: "Can view the wallet.",
    },
  },
  loginIpsTab: {
    title: "Login IP Address",
    createdAt: "$t(registeredAt)",
    ipAddress: "IP Address",
    empty: {
      title: "No registered IP addresses.",
      description:
        "Only login IP addresses that have completed authentication will be displayed.",
    },
  },
  updateWalletMemberRoleDialog: {
    title: "Change Role",
    description1: "'s wallet role in th wallet ",
    description2: " will be modified as follows.",
    walletOwner: {
      description:
        "Responsible for final approvals within the wallet. \nCan manage wallet members and approval lines.",
    },
  },
  deleteOrganizationMemberCompleteDialog: {
    title: "Deletion complete",
    description: " has been removed.",
  },
  deleteOrganizationMemberDialog: {
    title: "Remove Member",
    description1: "The following member will be removed from ",
    description2: ".",
    caution1:
      "Once the account is deleted, all account information will be removed, and data recovery will not be possible even if the account is re-added with the same email address in the future.",
    caution2:
      "However, any approvals or rejections requested by the account will not be deleted.",
  },
  organizationMemberTable: {
    resendInvitation: "Resend",
    cancelInvitation: "Cancel",
    inviteMember: "Invite Member",
  },
  accountSignupCompleteDialog: {
    title: "Sign Up Complete",
    description: "Your registration is complete. Please log in.",
  },
  registerAccountForm: {
    signup: "Sign Up",
    passwordPlaceholder: "Enter at least 8 characters",
    confirmPasswordPlaceholder: "Re-enter Password",
  },
  resetAccountPasswordForm: {
    title: "Set a New Password",
    description:
      "Please set a new password that is <span>at least 8 characters long and includes at least one uppercase letter, one lowercase letter, one number, and one special character.</span>",
    passwordPlaceholder: "New Password",
    confirmPasswordPlaceholder: "Re-enter Password",
    submit: "Set Password",
    complete: {
      title: "New Password Has Been Set",
      description: "Please log in again using your new password.",
    },
  },
  cancelRequestAddressBookDialog: {
    description: "Please review the address book information to be canceled.",
    create: "Cancel the address book registration request.",
    delete: "Cancel the address book deletion request.",
  },
  cancelRequestCompleteDialog: {
    title: "Request Cancellation Completed",
    description: "request has been canceled.",
  },
  rejectRequestAddressBookDialog: {
    create: "Reject the address book registration.",
    delete: "Reject the address book deletion.",
    description: "Please review the address book information to be rejected.",
  },
  requestAddressBookDialog: {
    create: {
      title: "Approve Address Book Registration",
      description:
        "Please review the address book information to be registered.",
    },
    delete: {
      title: "Approve Address Book Deletion",
      description: "Please review the address book information to be deleted.",
    },
  },
  cancelRequestWithdrawDialog: {
    title: "Cancel Withdrawal Request",
    description: "Please review the withdrawal information to be canceled.",
  },
  rejectRequestWithdrawDialog: {
    title: "Reject Withdrawal",
    description: "Please review the withdrawal information to be rejected.",
  },
  requestWithdrawDialog: {
    title: "Approve Withdrawal",
    description: "Please review the withdrawal information to be approved.",
  },
  clientRequestApprovalPolicy: {
    walletOwner: "Owner Approval",
    walletManager: "Manager or Higher Approval",
    required: "Required",
  },
  inboxTable: {
    empty: {
      title: "No Requests Received",
      description: "You can view requests from members or managers.",
      checkRole: "Check Member Roles",
    },
  },
  outboxTable: {
    empty: {
      title: "No Requests Sent",
      description: "You can send approval requests to managers or owners.",
      checkRole: "Check Member Roles",
    },
  },
  requestTable: {
    check: "Check Req",
    detail: "Details",
    content: "Content",
    createdAt: "Request",
  },
  setAccountNewPasswordForm: {
    description:
      "It has been <span>{{passphraseLastModifiedDayBefore}} days</span> since you last changed your password. <br/>For the safety of your assets, Coda recommends changing your password if it has not been updated for {{passphraseExpiredPeriodDays}} days. <br/><br/>Please set a new password with <span>at least 8 characters, including uppercase or lowercase letters, a number, and a special character.</span>",
    complete: {
      description: "A new password will be applied to your account.",
    },
  },
  passwordExpiredDialog: {
    title: "Please change your password.",
    changeLater: "Change Later",
  },
  noticeDialog: {
    title: "Notifications about Digital Asset Deposits and Withdrawals",
  },
  alreadyLoginDirectLogoutDialog: {
    title: "You have been logged out.",
    description:
      "You were logged out because you logged in from another browser.",
  },
  firstOrganizationMemberDialog: {
    title: "Welcome.",
    description:
      "Welcome to the KODA Custody Dashboard. \nInvite members to manage your organization's wallets.",
    invite: "Invite Members",
  },
  registerWallet: {
    title: "Create Wallet",
    steps: {
      step1: "Set Wallet Name",
      step2: "Set Wallet Address",
      step3: "Verify Account Password",
      step4: "$t(walletCreation)",
    },
    setWalletNameForm: {
      title: "Set Wallet Name",
      description: "Set the name for the new wallet.",
    },
    setWalletAddressForm: {
      title: "Set Wallet Address",
      description:
        "Set the wallet address you want to use. \nThe wallet address must consist of lowercase alphabets from a to z and numbers from 1 to 5, \nand it must be exactly 12 characters long.",
      randomInput: "Set Randomly",
      manualInput: "Set Manually",
    },
    setAccountPasswordForm: {
      title: "Verify Account Password",
      description:
        "Please enter your account password for identity verification.",
      label: "Password",
    },
    requestRegisterWalletOTPForm: {
      title: "Creating a Wallet",
      wallet: "Wallet",
    },
    error: {
      DUPLICATED_WALLET_NAME:
        "A wallet with the name '{walletName}' already exists. Please enter a different name.",
      WALLET_NOT_FOUND: "Wallet information does not exist.",
      DUPLICATED_WALLET_ADDRESS: "A wallet with the address already exists.",
      ADDRESS_MAX_LENGTH:
        "A standard wallet address must be 12 characters long.",
    },
  },
  registerCoinCompleteDialog: {
    title: "Coin Registration Complete",
    description:
      " registration has been complete. <br/>You can now use it in {{wallet}}.",
  },
  registerCoinOTPDialog: {
    title: "Registering the following coins.",
    description:
      "Please double-check the coin information to be registered, \nthen enter the 6-digit authentication code shown in Google Authenticator.",
  },
  registerCoinDialog: {
    title: "Register Coin",
    description: "Register the coin you want to use.",
    coinsToRegister: "Coins to Register",
    empty: "There are no coins available",
    emptyAlert1: "If you wish to use a coin not listed",
    emptyAlert2: "please contact us at",
  },
  deleteCoinOTPDialog: {
    title: "Remove Coin",
    description: "Removing the following coins.",
  },
  deleteCoinCompleteDialog: {
    title: "Coin Removal Complete",
    description: " has been removed.",
  },
  registerWalletEmptyStates: {
    title: "Processing is pending.",
    description1: "Please contact customer support(",
    description2:
      "), \nif the process is not completed within the indicated time.",
    checkDetail: "Details",
    alert1: "Wallet creation takes up to <span>1 business day.</span>",
    alert2:
      "<span>Only one wallet creation or withdrawal request</span> is processed per day.",
    alert3:
      "If there is a pending withdrawal request, \na new wallet creation request will be processed 1 business day after the wallet creation is completed.",
  },
  requestProceedingEmptyStates: {
    title: "No Processing History",
    description:
      "Approved withdrawal or wallet creation history will be displayed here.",
  },
  withdrawSalesAlert: {
    alert1: "Withdrawal takes up to <span>1 business day.</span>",
    alert2:
      "<span>Only one wallet creation or withdrawal request</span> is processed per day.",
    alert3:
      "If there is a pending withdrawal request, \na new withdrawal request will be processed 1 business day after the wallet creation is completed.",
  },
  registerAddressBookDialog: {
    title: "Add Address Book",
    previous: "Back",
    label: {
      name: "Wallet Address Name",
    },
    placeholder: {
      address: "Enter the wallet address to register.",
      memo: "Enter the MEMO to register. (Optional)",
      name: "Set a name for the address book.",
    },
    receiverForm: {
      title: "Enter Recipient Information",
      koreanLastName: "Korean Last Name",
      koreanFirstName: "Korean First Name",
      englishLastName: "English Last Name",
      englishFirstName: "English First Name",
      walletType: {
        label: "Wallet Type",
        personal: "Personal Verification Wallet",
        vasp: "Exchange (or other Virtual Asset Service Provider)",
        info: "[Personal Verification Wallet] requires submission of an address listed in the “Personal Wallet Ownership Certificate” or supporting documents proving the reason for withdrawal.",
      },
      vasp: "Virtual Asset Service Provider",
      receiverType: {
        label: "Recipient Type",
        natural: "Individual",
        legal: "Corporation",
      },
      corporationName: {
        label: "Corporation Name",
        placeholder: "Enter the corporation name.",
      },
      representativeName: "Corporate Representative Name",
      receiverName: "Recipient Name",
      receiverAddress: {
        label: "Recipient Address",
        placeholder:
          "Enter the recipient's address. (e.g., 123 Main St, New York, NY)",
      },
      receiverPhoneNumber: {
        label: "Recipient Contact Number",
        placeholder:
          "Enter the recipient's contact number. (e.g., +821012345678)",
      },
    },
  },
  walletMemberTable: {
    columns: {
      role: "Role",
    },
    empty: {
      title: "Empty Wallet Members",
      description: "Please Add Wallet Members",
    },
    addMember: "Add Wallet Member",
  },
  cancelRegisterWalletCompleteDialog: {
    title: "Wallet Creation Cancellation Complete",
    description: "Wallet creation has been canceled.",
  },
  cancelRegisterWalletDialog: {
    title: "Canceling wallet creation.",
    description:
      "Please review the wallet creation information to be canceled once more.",
    registerWalletName: "Wallet",
  },
  requestRegisterWalletCompleteDialog: {
    title: "Wallet Creation Request Complete",
    description:
      "The wallet creation has been requested. <br/>It will take <span>1 business day</span> to complete the wallet creation.",
  },
  updateWalletNameCompleteDialog: {
    title: "Wallet Name Change Complete",
    description: "Wallet name has been changed to <span>{{name}}</span>",
  },
  updateWalletNameDialog: {
    title: "Change Wallet Name",
    description: "Enter the new wallet name.",
    placeholder: "Enter new wallet name",
  },
  requestWithdrawCompleteDialog: {
    title1: "Withdrawal Approval Request Complete",
    title2: "Withdrawal Processing Request Complete",
    description1:
      "The withdrawal of <span>{{coin}}</span> has been requested for approval. <br/>Once the approval is complete, Koda will begin processing the withdrawal.",
    description2:
      "The withdrawal of <span>{{coin}}</span> has been requested for processing. <br/>It will take <span>1 business day</span> to complete the withdrawal.",
    check1: "Check the approval",
    check2: "Check processing status",
  },
  registerAddressBookCompleteDialog: {
    title: "Address Book Registration Complete",
    description: "The address book has been successfully registered.",
  },
  deleteAddressBookCompleteDialog: {
    title: "Address Book Deletion Complete",
    description: "The address book has been successfully deleted.",
  },
  requestAddressBookCompleteDialog: {
    create: {
      title: "Address Book Registration Approval Request Complete",
      description:
        "The address registration has been requested for approval. \nOnce approved, the address book will be registered.",
    },
    delete: {
      title: "Address Book Deletion Approval Request Complete",
      description:
        "The address deletion has been requested for approval. \nOnce approved, the address book will be deleted.",
    },
  },
  deleteAddressBookDialog: {
    title: "Do you want to delete the address book?",
    description: "Deleting <span>{{addressBook}}</span>.",
  },
  updatePolicyDialog: {
    title: "Change {{content}} Approval Line",
    description: {
      walletOwner: "Approval from the owner in charge is required.",
      walletManager: "Approval from a wallet manager or the owner is required.",
    },
  },
  updatePolicyCompleteDialog: {
    title: "Withdrawal Approval Line Change Complete",
    description: "Approval Line has been changed to <span>{{name}}</span>.",
  },
  deleteInvitedWalletMemberDialog: {
    title: "Remove Member",
    description:
      "The following member will be removed from the {{walletName}} wallet.",
  },
  deleteInvitedWalletMemberCompleteDialog: {
    title: "Removal Complete",
    description:
      "<span>{{memberName}}</span> has been removed from the {{walletName}} wallet.",
  },
  updateWalletMemberRoleCompleteDialog: {
    title: "Role Change Complete",
    description: "The role of <span>{{memberName}}</span> has been updated.",
  },
  invitedWalletMemberDialog: {
    title: "Invite Member",
    description: "Select the member to invite to the {{walletName}} wallet.",
  },
  invitedWalletMemberCompleteDialog: {
    title: "Invitation Complete",
    description1: " and {{count}} others",
    description2: " has been invited to the {{walletName}} wallet.",
  },
  invitedOrganizationMemberDialog: {
    title: "Invite Member",
    description: "Enter the email of the member you want to invite.",
    placeholder: "Enter the email and press the Enter key or Spacebar.",
  },
  registerWalletInviteInfoDialog: {
    title: "Invite Other Members to the Wallet",
    description:
      "You can invite other members to manage the wallet together. \nAssign different roles to each member for secure collaboration.",
    dontShowAgain: "$t(dontShowAgain)",
    invite: "Invite Members",
  },
  firstPolicyEntryDialog: {
    title: "Set the Wallet Approval Line",
    description:
      "Configure the approval line for withdrawals and address book registration/deletion.",
    confirm: "Set Approval Line",
  },
  addressBookEmptyStates: {
    title: "No Registered Addresses",
    description:
      "Withdrawals can only be made to addresses registered in the address book.",
    add: "Register Address",
  },
  addressBookDetailDialog: {
    title: "Address Book Details",
    receiverName: "Recipient Name",
    receiverKoName: "Recipient Name (Korean)",
    receiverEnName: "Recipient Name (English)",
  },
  requestReasonDialog: {
    title: "Provide a Reason for the Approval Request",
    description:
      "To proceed with {{request}}, <span>{{approvalLine}}</span> or higher approval is required. <br/>Once all approvals are complete, the {{request}} will be processed.",
    placeholder: "Enter the reason for the approval request.",
    addressBook: {
      create: "Address Book Registration",
      delete: "Address Book Deletion",
    },
  },
  cancelWithdrawDialog: {
    title: "Withdrawal Cancellation",
    description: "Please review the withdrawal information to be canceled.",
  },
  requestStep: {
    request: "Requested",
    signing: "Approving",
    proceeding: "Processing",
    complete: "Complete",
  },
  roles: {
    organizationSuperAdmin: "Super Administrator",
    organizationWalletsAdmin: "Wallet Administrator",
    organizationUsersAdmin: "Member Administrator",
    organizationUser: "Member",
    walletOwner: "Wallet Owner",
    walletManager: "Wallet Manager",
    walletMember: "Wallet Member",
    walletViewer: "Wallet Viewer",
    custodyAdmin: "Custody Administrator",
    custodyManager: "Custody Manager",
    custodySigner: "Custody Signer",
    custodyViewer: "Custody Viewer",
    custodyOperator: "Custody Operator",
    custodyAccountEmailViewer: "Custody Email Viewer",
  },
  walletRoles: {
    walletOwner: "Owner",
    walletManager: "Manager",
    walletMember: "Member",
    walletViewer: "Viewer",
  },
  addressBook: {
    label: "Address Book",
    create: "Address Book Registration",
    delete: "Address Book Deletion",
    deleted: "Deleted Address Book",
    error: {
      DUPLICATED_NAME: "The same address name already exists in the wallet.",
      DUPLICATED_NAME_REQUESTED:
        "A registration request for the same address name is already pending.",
      DUPLICATED_ADDRESS_AND_COIN:
        "An address book with the same address and coin/token already exists in the wallet.",
      DUPLICATED_ADDRESS:
        "A registration request for the same address is already pending.",
      INVALID_ADDRESS: "The address is invalid.",
    },
  },
  requestStatus: {
    ALL: "All Statuses",
    REQUEST_PENDING: "Approval Pending",
    REQUEST_APPROVAL: "Approval Complete",
    REQUEST_REJECT: "Approval Rejected",
    PROCEEDING_PENDING: "Processing Pending",
    PROCEEDING: "Processing",
    PROCEEDING_REJECT: "Processing Rejected",
    PROCEEDING_CANCEL: "Processing Canceled",
    TRANSFER: "Transferring",
    TRANSFER_FAILED: "Transfer Failed",
    TRANSFER_COMPLETE: "Transfer Complete",
    DEPOSIT: "Deposit Pending",
    DEPOSIT_COMPLETE: "Deposit Complete",
    CREATING: "Creating",
    CREATING_FAILED: "Creation Failed",
    CREATING_COMPLETE: "Creation Complete",
  },
  approverSigningStatus: {
    pending: "Pending",
    approve: "Approved",
    skipApprove: "approval skipped",
    reject: "Rejected",
  },
};

export default en;
